/* eslint jsx-a11y/anchor-is-valid: "off" */

import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';

class Navbar extends Component {
  state = {
    isAuthenticated: false,
  };

  onLogoutClick = e => {
    e.preventDefault();
    const { firebase } = this.props;
    firebase.logout();
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;

    if (auth.uid) {
      return { isAuthenticated: true };
    }
    return { isAuthenticated: false };
  }

  render() {
    const { profile, auth } = this.props;
    const { isAuthenticated } = this.state;
    // console.log(profile);

    return (
      <nav
        style={{ borderTop: '2px solid #9e78ff' }}
        className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
      >
        <div className="navbar-header">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item mobile-menu d-md-none mr-auto">
              <a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                <i className="ft-menu font-large-1" />
              </a>
            </li>
            <li className="nav-item d-md-none">
              <a className="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile">
                <i className="la la-ellipsis-v" />
              </a>
            </li>
          </ul>
        </div>
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              {isAuthenticated ? (
                <ul className="nav navbar-nav mr-auto float-left">
                  <li className="nav-item d-none d-md-block">
                    <NavLink exact className="nav-link" activeClassName="active nav-link" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <NavLink className="nav-link" activeClassName="active nav-link" to="/projects">
                      Projects
                    </NavLink>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <NavLink className="nav-link" activeClassName="active nav-link" to="/users">
                      People
                    </NavLink>
                  </li>
                  <li className="nav-item d-none d-md-block">
                    <NavLink className="nav-link" activeClassName="active nav-link" to="/settings">
                      Settings
                    </NavLink>
                  </li>
                  {profile && profile.isSuperAdmin ? (
                    <>
                      <li className="nav-item d-none d-md-block">
                        <NavLink className="nav-link" activeClassName="active" to="/register/admin">
                          Register
                        </NavLink>
                      </li>
                    </>
                  ) : null}
                </ul>
              ) : null}

              <ul className="nav navbar-nav float-right">
                <li className="nav-item d-none d-md-block">
                  <NavLink to={`/user/${auth.uid}`} className="nav-link text-uppercase">
                    {`${profile.firstName || ''} ${profile.lastName || ''}`}
                  </NavLink>
                </li>
                <li className="dropdown dropdown-user nav-item">
                  <Link className="dropdown-toggle nav-link dropdown-user-link" to={`/user/${auth.uid}`}>
                    <span className="avatar avatar-online">
                      <img
                        src={profile.avatarURL || '/assets/images/temp.jpg'}
                        alt={`${profile.firstName || ''} ${profile.lastName || ''}`}
                      />
                    </span>
                  </Link>
                </li>
              </ul>

              {isAuthenticated ? (
                <ul className="nav navbar-nav float-right">
                  <li className="nav-item d-none d-md-block">
                    <NavLink className="nav-link" to="/logout" onClick={this.onLogoutClick} exact>
                      Logout
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul className="nav navbar-nav float-right">
                  <li className="nav-item d-none d-md-block">
                    <NavLink className="nav-link" to="/login" exact>
                      Login
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }))
)(Navbar);
