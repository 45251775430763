import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

import ProjectSummary from './ProjectSummary';
import ProjectTasksActive from './ProjectTasksActive';
import ProjectTasksFinished from './ProjectTasksFinished';
import CardNotification from './CardNotification';

class ProjectTasks extends Component {
  state = {};

  render() {
    const { tasks, project, firestore, auth } = this.props;

    return (
      <>
        <ProjectSummary project={project} tasks={tasks} firestore={firestore} auth={auth} />
        {tasks && tasks.length === 0 ? (
          <CardNotification
            cardTitle="No tasks in project!"
            cardBody="Use 'Add task' form to add a new task..."
            border="card box-shadow-1"
          />
        ) : null}
        {project.tasksActiveCount > 0 ? (
          <ProjectTasksActive tasks={tasks} firestore={firestore} pid={project.id} project={project} />
        ) : (
          <CardNotification
            cardTitle="No active tasks right now!"
            cardBody="Use 'Add task' form to add a new task..."
            border="bg-transparent card box-shadow-0"
          />
        )}
        {/* {tasks && tasks.filter(task => task.taskFinished === true).length > 0 ? ( */}
        {project.tasksFinishedCount > 0 ? (
          <ProjectTasksFinished tasks={tasks} firestore={firestore} pid={project.id} />
        ) : (
          <CardNotification
            cardTitle="No finished tasks!"
            cardBody="Use 'DONE' checkbox to switch from active to finished"
            border="bg-transparent card box-shadow-0"
          />
        )}
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: `projects/${props.project.id}/tasks`,
      storeAs: 'tasks',
      limit: 100,
      orderBy: ['orderNo', 'asc'],
      // orderBy: ['createdAt', 'desc'],
    },
  ]),
  connect((state, props) => ({
    tasks: state.firestore.ordered.tasks,
    auth: state.firebase.auth,
  }))
)(ProjectTasks);
