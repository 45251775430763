/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import Spinner from '../layout/Spinner';

class EditSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCompanySection: '',
      newTaskTag: '',
      isUploading: false,
      progress: 0,
    };
    this.companyNameInput = createRef();
    this.companyShortNameInput = createRef();
    this.projectsAllowedInput = createRef();
    this.usersAllowedInput = createRef();
    this.isActiveInput = createRef();
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { settings, firestore, history } = this.props;
    const { isUploading, progress } = this.state;
    const updatedSettings = {
      companyName: this.companyNameInput.current.value,
      companyShortName: this.companyShortNameInput.current.value,
      isActive: this.isActiveInput.current.checked,
      // avatarURL: avatarURL || this.avatarURLInput.current.value,
      isUploading,
      progress,
    };

    firestore.update({ collection: 'settings', doc: settings.id }, updatedSettings).then(history.push('/'));
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    const { firebase } = this.props;
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('photos')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        // console.log(url);
        // eslint-disable-next-line react/no-unused-state
        this.setState({ avatarURL: url });
      });
  };

  render() {
    const { settings, firestore, profile } = this.props;
    const { newCompanySection, newTaskTag } = this.state;
    // console.log(profile);

    return (
      <>
        <Helmet>
          <body
            className="horizontal-layout horizontal-menu 2-columns menu-expanded"
            data-open="hover"
            data-menu="horizontal-menu"
            data-col="2-columns"
            data-color="bg-gradient-x-pink-orange"
          />
        </Helmet>
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Edit Settings</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/settings">Settings</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {!isLoaded(settings) ? (
          <Spinner />
        ) : isEmpty(settings) ? (
          'Settings not available!'
        ) : (
          <div className="container">
            <div className="card">
              <div className="card-header">
                <h4>{`Edit ${settings.companyName} settings`}</h4>
              </div>
              <div className="card-body">
                <form onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-check-label" htmlFor="isActive">
                        <strong>Company status:</strong>
                      </label>
                      <div className="form-group form-check p-1 text-center bg-success">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="isActive"
                          id="isActive"
                          ref={this.isActiveInput}
                          defaultChecked={!!settings.isActive}
                        />
                        <label className="form-check-label" htmlFor="isActive">
                          <strong>Company is Active</strong>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="accountName">
                          <strong>Account Name:</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="accountName"
                          id="accountName"
                          ref={this.accountNameInput}
                          defaultValue={profile.companyName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="companyName">
                          <strong>Company Name:</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="companyName"
                          id="companyName"
                          placeholder="Company Name..."
                          ref={this.companyNameInput}
                          defaultValue={settings.companyName}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="companyShortName">
                          <strong>Company Short Name:</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="companyShortName"
                          id="companyShortName"
                          placeholder="Company Short Name..."
                          ref={this.companyShortNameInput}
                          defaultValue={settings.companyShortName}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="projectsAllowed">
                          <strong>Projects Allowed:</strong>
                        </label>
                        <input
                          onChange={e => {
                            const target = e.target;
                            const value = target.value;

                            firestore.update(
                              { collection: 'settings', doc: settings.id },
                              { projectsAllowed: parseFloat(value) }
                            );
                          }}
                          type="number"
                          className="form-control"
                          name="projectsAllowed"
                          id="projectsAllowed"
                          placeholder="Projects Allowed..."
                          ref={this.projectsAllowedInput}
                          defaultValue={settings.projectsAllowed}
                          disabled={!profile.isSuperAdmin}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="usersAllowed">
                          <strong>Users Allowed:</strong>
                        </label>
                        <input
                          onChange={e => {
                            const target = e.target;
                            const value = target.value;

                            firestore.update(
                              { collection: 'settings', doc: settings.id },
                              { usersAllowed: parseFloat(value) }
                            );
                          }}
                          type="number"
                          className="form-control"
                          name="usersAllowed"
                          id="usersAllowed"
                          placeholder="Users Allowed..."
                          ref={this.usersAllowedInput}
                          defaultValue={settings.usersAllowed}
                          disabled={!profile.isSuperAdmin}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="newCompanySection">
                        <strong>New Company Section:</strong>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="newCompanySection"
                          id="newCompanySection"
                          placeholder="Enter new company section..."
                          onChange={this.onChange}
                          value={newCompanySection}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              firestore.update(
                                { collection: 'settings', doc: settings.id },
                                {
                                  companySections: firestore.FieldValue.arrayUnion(newCompanySection),
                                }
                              );
                              this.setState({ newCompanySection: '' });
                            }}
                          >
                            Add new
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="companySection">
                        <strong>Company Sections:</strong>
                      </label>
                      <div className="d-block">
                        {settings &&
                          settings.companySections.map(setting => (
                            <span
                              className="badge badge-pill badge-lg badge-primary"
                              key={setting}
                              style={{ margin: '3px' }}
                            >
                              {setting}
                            </span>
                          ))}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="newTaskTag">
                        <strong>New Task Tag:</strong>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="newTaskTag"
                          id="newTaskTag"
                          placeholder="Create new task tag..."
                          onChange={this.onChange}
                          value={newTaskTag}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              firestore.update(
                                { collection: 'settings', doc: settings.id },
                                {
                                  taskTags: firestore.FieldValue.arrayUnion(newTaskTag),
                                }
                              );
                              this.setState({ newTaskTag: '' });
                            }}
                          >
                            Add new
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="taskTag">
                        <strong>Task Tags:</strong>
                      </label>
                      <div className="d-block">
                        {(settings &&
                          settings.taskTags.map(setting => (
                            <span
                              className="badge badge-pill badge-lg badge-info"
                              key={setting}
                              style={{ margin: '3px' }}
                            >
                              {setting}
                            </span>
                          ))) ||
                          ''}
                      </div>
                    </div>
                    <div className="col-md-6" />
                  </div>

                  <button className="btn btn-rounded btn-primary btn-lg btn-block" type="submit">
                    Update company settings
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
        <div className="container">
          <Link className="btn btn-outline-dark btn-block" to="/projects">
            <i className="ft-arrow-left pr-1" />
            or cancel and go back...
          </Link>
        </div>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    settings: state.firestore.ordered.settings && state.firestore.ordered.settings[0],
  })),
  firestoreConnect(props => [
    {
      collection: 'settings',
      where: [['accountName', '==', props.profile.isLoaded && props.profile.companyName]],
      storeAs: 'settings',
    },
  ])
)(EditSettings);
