/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, createRef } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';
import Datetime from 'react-datetime';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import Modal from 'react-modal';
// import 'firebase/storage';

import 'react-quill/dist/quill.snow.css';
import './DateTimePicker.css';

import Spinner from '../layout/Spinner';
import AppWarning from '../layout/AppWarning';
import ProjectSummary from './ProjectSummary';

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.titleInput = createRef();
    this.descriptionInput = createRef();
    this.bodyInput = createRef();
    this.startDateInput = createRef();
    this.endDateInput = createRef();
    this.isPrivateInput = createRef();
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  onDeleteClick = () => {
    const { project, firestore, history } = this.props;
    firestore.delete({ collection: 'projects', doc: project.id }).then(history.push('/projects'));
  };

  onSubmit = e => {
    e.preventDefault();
    const { project, firestore, history, auth } = this.props;
    const updatedProject = {
      title: this.titleInput.current.value,
      description: this.descriptionInput.current.value,
      startDate: this.startDateInput.current.state.selectedDate._d,
      endDate: this.endDateInput.current.state.selectedDate._d,
      isPrivate: this.isPrivateInput.current.checked,
      isPrivateAuthor: this.isPrivateInput.current.checked ? auth.email : null,
    };

    firestore
      .update({ collection: 'projects', doc: project.id }, updatedProject)
      .then(history.push(`/project/${project.id}`));
  };

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { project, tasks, firestore, auth } = this.props;
    const { showModal } = this.state;

    if (project) {
      // console.log('auth ', auth.uid);
      // console.log('project ', project.authorId);

      return (
        <>
          <Helmet>
            <body
              className="horizontal-layout horizontal-menu 2-columns menu-expanded"
              data-open="hover"
              data-menu="horizontal-menu"
              data-col="2-columns"
              data-color="bg-gradient-x-pink-orange"
            />
          </Helmet>
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <h3 className="content-header-title">
                <span style={{ color: '#ee0979' }}>EDIT PROJECT:</span>
                <span style={{ color: '#fff' }}>{` ${project.title}`}</span>
              </h3>
            </div>
            <div className="content-header-right col-md-3 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/projects">Projects</a>
                    </li>
                    {/* <li className="breadcrumb-item active">{project.title}</li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {project.authorId === auth.uid ? (
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  {/* <h4 className="card-header">Edit project:</h4> */}
                  <div className="card-body">
                    {/* <div dangerouslySetInnerHTML={{ __html: project.body }} /> */}
                    <form onSubmit={this.onSubmit}>
                      <div className="form-group">
                        <label htmlFor="title">
                          <strong>Title:</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          id="title"
                          minLength="2"
                          placeholder="title"
                          ref={this.titleInput}
                          defaultValue={project.title}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="startDate">
                              <strong>Start Date:</strong>
                            </label>
                            <Datetime
                              id="startDate"
                              ref={this.startDateInput}
                              defaultValue={project.startDate.toDate()}
                              inputProps={{ placeholder: 'Select start date...' }}
                              onChange={this.handleStartDate}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="endDate">
                              <strong>End Date:</strong>
                            </label>
                            <Datetime
                              id="endDate"
                              ref={this.endDateInput}
                              defaultValue={project.endDate.toDate()}
                              inputProps={{ placeholder: 'Select end date...' }}
                              onChange={this.handleEndDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="description">
                          <strong>Description:</strong>
                        </label>
                        <textarea
                          className="form-control"
                          rows="3"
                          name="description"
                          id="description"
                          minLength="2"
                          placeholder="description"
                          ref={this.descriptionInput}
                          defaultValue={project.description}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="body">
                          <strong>Body:</strong>
                        </label>
                        <ReactQuill
                          name="body"
                          id="body"
                          defaultValue={project.body}
                          modules={EditProject.modules}
                          formats={EditProject.formats}
                          theme="snow"
                          onChange={e => {
                            const updatedBody = {
                              body: DOMPurify.sanitize(e),
                            };
                            firestore.update({ collection: 'projects', doc: project.id }, updatedBody);
                          }}
                        />
                      </div>

                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="isPrivate"
                          id="isPrivate"
                          ref={this.isPrivateInput}
                          defaultChecked={!!project.isPrivate}
                        />
                        <label className="form-check-label" htmlFor="isPrivate">
                          Project is private:
                        </label>
                      </div>

                      <button className="btn btn-rounded btn-lg mr-1 btn-primary" type="submit">
                        Update project
                      </button>
                      {'  '}
                      <button
                        className="btn btn-rounded btn-lg mr-1 btn-link"
                        type="submit"
                        onClick={() => <Redirect to={`/project/${project.id}`} />}
                      >
                        &larr; or cancel and go back
                      </button>
                      {'  '}
                      {/* <button
                        onClick={this.onDeleteClick}
                        className="btn btn-lg btn-rounded btn-danger pull-right"
                        type="submit"
                      >
                        Delete project
                      </button> */}
                      <button
                        onClick={this.handleOpenModal}
                        className="btn btn-lg btn-rounded btn-danger pull-right"
                        type="button"
                      >
                        Delete project
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <ProjectSummary project={project} tasks={tasks} />
              </div>
            </div>
          ) : (
            <AppWarning data="project" dataId={project.id} />
          )}

          <Modal
            isOpen={showModal}
            contentLabel="Delete Project Modal Confirmation"
            style={{
              overlay: {
                backgroundColor: 'rgba(0,0,0,.5)',
              },
              content: {
                color: 'black',
                margin: '5%',
              },
            }}
          >
            <div className="text-center">
              <h3 className="">Are you sure you want to delete:</h3>
              <h2>{`${project.title}?`}</h2>
              <button onClick={this.onDeleteClick} className="btn btn-lg btn-rounded btn-danger col-md-4" type="submit">
                YES, Delete Project
              </button>
              {'  '}
              <button
                onClick={this.handleCloseModal}
                className="btn btn-lg btn-rounded btn-primary col-md-4"
                type="button"
              >
                NO, Close this dialog
              </button>
            </div>
          </Modal>
        </>
      );
    }
    return <Spinner />;
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
EditProject.modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
EditProject.formats = [
  // 'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    project: state.firestore.ordered.project && state.firestore.ordered.project[0],
    tasks: state.firestore.ordered.tasks && state.firestore.ordered.tasks,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  })),
  firestoreConnect(props => [
    {
      collection: 'projects',
      storeAs: 'project',
      doc: props.match.params.id,
    },
    {
      collection: `projects/${props.match.params.id}/tasks`,
      storeAs: 'tasks',
      limit: 100,
      orderBy: ['orderNo', 'asc'],
      // orderBy: ['createdAt', 'desc'],
    },
  ])
)(EditProject);
