import React, { Component } from 'react';
import Nav from './Nav';
import Footer from './Footer';

class Layout extends Component {
  state = {};

  render() {
    const { children } = this.props;
    return (
      <>
        <Nav />
        <div className="app-content content mb-3">
          <div className="content-wrapper">{children}</div>
        </div>
        <Footer />
      </>
    );
  }
}
export default Layout;
