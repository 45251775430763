/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';

import './DateTimePicker.css';

class AddProject extends Component {
  state = {
    title: '',
    description: '',
    slug: '',
    startDate: '',
    endDate: '',
    createdAt: new Date(),
  };

  handleStartDate = e => {
    this.setState({
      startDate: e._d,
    });
  };

  handleEndDate = e => {
    this.setState({
      endDate: e._d,
    });
  };

  onChange = e => {
    const newDate = String(new Date().getTime());
    const date = newDate.slice(9);
    const { title } = this.state;
    this.setState({ [e.target.id]: e.target.value });
    this.setState({
      slug: slugify(`${title}-${date}`, {
        lower: true,
      }),
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { title, description, createdAt, slug, startDate, endDate } = this.state;
    const { firestore, history, profile, auth } = this.props;

    firestore
      .collection('projects')
      .add({
        title,
        description,
        slug,
        startDate,
        endDate,
        createdAt,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorCompany: profile.companyName,
        authorEmail: profile.email,
        authorPhoto: profile.avatarURL,
        authorId: auth.uid,
        isPrivate: false,
      })
      .then(() => history.push('/projects'));
  };

  render() {
    const { title, description } = this.state;

    return (
      <>
        <Helmet>
          <body
            className="horizontal-layout horizontal-menu 2-columns menu-expanded"
            data-open="hover"
            data-menu="horizontal-menu"
            data-col="2-columns"
            data-color="bg-gradient-x-pink-orange"
          />
        </Helmet>
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Add new project</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/projects">Projects</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* <Link to="/" className="btn btn-link">
              <i className="fas fa-arrow-circle-left" />
              back to dashboard
            </Link> */}
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>Create New Project</h4>
            </div>
            <div className="card-body">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="title">Title:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="title"
                    minLength="2"
                    placeholder="Enter project title..."
                    onChange={this.onChange}
                    value={title}
                    required
                  />
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="startDate">Start Date:</label>
                      <Datetime
                        id="startDate"
                        inputProps={{ placeholder: 'Select start date...' }}
                        onChange={this.handleStartDate}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="endDate">End Date:</label>
                      <Datetime
                        id="endDate"
                        inputProps={{ placeholder: 'Select end date...' }}
                        onChange={this.handleEndDate}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="body">Description:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    id="description"
                    minLength="2"
                    placeholder="Enter project description..."
                    onChange={this.onChange}
                    value={description}
                  />
                </div>
                <button className="btn btn-rounded btn-primary btn-lg btn-block" type="submit">
                  Create new project
                  <i className="ft-arrow-right pl-1" />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="container">
          <Link className="btn btn-outline-dark btn-block" to="/projects">
            <i className="ft-arrow-left pr-1" />
            cancel and go back...
          </Link>
        </div>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(AddProject);
