import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import Spinner from '../layout/Spinner';
import TableUsers from './TableUsers';
import Register from '../auth/Register';

class Users extends Component {
  state = {};

  render() {
    const { users, firestore, profile } = this.props;
    // console.log(profile);
    return (
      <>
        <Helmet>
          <body
            className="horizontal-layout horizontal-menu 2-columns menu-expanded"
            data-open="hover"
            data-menu="horizontal-menu"
            data-col="2-columns"
            data-color="bg-gradient-x-pink-orange"
          />
        </Helmet>
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">People</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/users">People</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            {users ? <TableUsers users={users} firestore={firestore} profile={profile} /> : <Spinner />}
          </div>
          <div className="col-md-4">
            <Register />
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    users: state.firestore.ordered.users,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  })),
  firestoreConnect([
    {
      collection: 'users',
    },
  ])
)(Users);
