import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { UserIsAuthenticated, UserIsNotAuthenticated } from '../helpers';

import store from '../store';

import App from '../App';
import MainLayout from '../components/layout/Layout';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import RegisterAdmin from '../components/auth/RegisterAdmin';
import User from '../components/users/User';
import Users from '../components/users/Users';
import Project from '../components/projects/Project';
import Projects from '../components/projects/Projects';
import ReorderProjects from '../components/projects/ReorderProjects';
import AddProject from '../components/projects/AddProject';
import ProjectTask from '../components/projects/ProjectTask';
import EditProject from '../components/projects/ProjectEdit';
import EditProjectTask from '../components/projects/ProjectTaskEdit';
import ProjectTaskComment from '../components/projects/ProjectTaskComment';
import EditSettings from '../components/settings/EditSettings';
import AddSettings from '../components/settings/AddSettings';
import EditUser from '../components/users/EditUser';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const AppRouter = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/login" component={UserIsNotAuthenticated(Login)} />
        <AppRoute exact path="/" layout={MainLayout} component={UserIsAuthenticated(App)} />
        <AppRoute exact path="/register" layout={MainLayout} component={UserIsAuthenticated(Register)} />
        <AppRoute exact path="/register/admin" layout={MainLayout} component={UserIsAuthenticated(RegisterAdmin)} />
        <AppRoute exact path="/user/:id" layout={MainLayout} component={UserIsAuthenticated(User)} />
        <AppRoute exact path="/user/:id/edit" layout={MainLayout} component={UserIsAuthenticated(EditUser)} />
        <AppRoute exact path="/users" layout={MainLayout} component={UserIsAuthenticated(Users)} />
        <AppRoute exact path="/projects" layout={MainLayout} component={UserIsAuthenticated(Projects)} />
        <AppRoute exact path="/projects/add" layout={MainLayout} component={UserIsAuthenticated(AddProject)} />
        <AppRoute exact path="/project/:id" layout={MainLayout} component={UserIsAuthenticated(Project)} />
        <AppRoute
          exact
          path="/project/:id/task/:tid/"
          layout={MainLayout}
          component={UserIsAuthenticated(ProjectTask)}
        />
        <AppRoute
          exact
          path="/project/:id/task/:tid/edit"
          layout={MainLayout}
          component={UserIsAuthenticated(EditProjectTask)}
        />
        <AppRoute
          exact
          path="/project/:id/task/:tid/comment/:cid"
          layout={MainLayout}
          component={UserIsAuthenticated(ProjectTaskComment)}
        />
        <AppRoute exact path="/project/:id/edit" layout={MainLayout} component={UserIsAuthenticated(EditProject)} />
        <AppRoute exact path="/settings/add" layout={MainLayout} component={UserIsAuthenticated(AddSettings)} />
        <AppRoute exact path="/settings/" layout={MainLayout} component={UserIsAuthenticated(EditSettings)} />
        <AppRoute exact path="/reorder/:id" layout={MainLayout} component={UserIsAuthenticated(ReorderProjects)} />
        <AppRoute layout={MainLayout} component={UserIsAuthenticated(App)} />
      </Switch>
    </Router>
  </Provider>
);

export default AppRouter;
