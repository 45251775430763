/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = sortableElement(({ value }) => <li>{value.title}</li>);

const SortableContainer = sortableContainer(({ children }) => <ul>{children}</ul>);

class ReorderProjectsCard extends Component {
  constructor(props) {
    super(props);
    const { tasks, tasksId, firestore } = this.props;
    console.log(tasks);

    this.onUpdateItem = i => {
      this.setState(state => {
        const list = state.list.map((item, j) => {
          if (j === i) {
            return item + 1;
          }
          return item;
        });

        return {
          list,
        };
      });
    };

    this.onSortEnd = ({ oldIndex, newIndex }) => {
      firestore.update(
        { collection: `projects/FVp4Dg8OTr67q0ecVyIA/tasks`, doc: tasksId },
        { items: arrayMove(tasks, oldIndex, newIndex), createdAt: new Date() }
      );
    };
  }

  render() {
    const { tasks } = this.props;
    // const { items } = this.state;
    // console.log(tasks);

    return (
      <SortableContainer onSortEnd={this.onSortEnd}>
        {tasks &&
          tasks.sort().map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SortableItem key={value.id} index={index} value={value} />
          ))}
      </SortableContainer>
    );
  }
}

export default ReorderProjectsCard;
