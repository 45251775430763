import React from 'react';

const CardNotification = ({ cardTitle, cardBody, border }) => (
  <div className={border} style={(border, { border: '1px solid #ccc' })}>
    <div className="card-content collapse show">
      <div className="card-body">
        <h4 className="card-title">{cardTitle}</h4>
        <p className="card-text">{cardBody}</p>
      </div>
    </div>
  </div>
);

export default CardNotification;
