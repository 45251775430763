/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import { Link } from 'react-router-dom';

const TableUsers = props => {
  const { users, firestore, profile } = props;

  return (
    <section className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-head">
            <div className="card-header">
              <h4 className="card-title">
                <strong>All people</strong>
              </h4>
              <a className="heading-elements-toggle">
                <i className="la la-ellipsis-h font-medium-3" />
              </a>
            </div>
          </div>
          <div className="card-content">
            <div className="card-body">
              <div className="table-responsive">
                <table
                  id="users-contacts"
                  className="table table-white-space table-bordered table-striped row-grouping display no-wrap icheck table-middle"
                >
                  <thead>
                    <tr>
                      <th />
                      <th>Name</th>
                      <th>Email</th>
                      <th>Company</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users &&
                      users
                        .filter(a => a.email !== 'ipavic@mac.com')
                        .map(user => (
                          <tr key={user.id}>
                            <td className="text-center">
                              <span className="avatar avatar-sm rounded-circle">
                                <img
                                  src={user.avatarURL || '/assets/images/temp.jpg'}
                                  alt={`${user.firstName || ''} ${user.lastName || ''}`}
                                />
                              </span>
                            </td>
                            <td>
                              <div className="media">
                                <div className="media-left pr-1">
                                  <div className="media-body media-middle">
                                    <Link to={`/user/${user.id}`}>
                                      <span className="media-heading text-bold-700">{user.firstName || ''}</span>
                                      &nbsp;
                                      <span className="media-heading text-bold-700">{user.lastName || ''}</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="text-left">{user.email || ''}</td>
                            <td>{user.companyName || ''}</td>
                            <td>
                              <span className="dropdown">
                                <button
                                  id="btnSearchDrop12"
                                  type="button"
                                  className="btn btn-sm btn-icon btn-pure font-medium-2"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="ft-more-vertical" />
                                </button>
                                <span
                                  aria-labelledby="btnSearchDrop12"
                                  className="dropdown-menu mt-1 dropdown-menu-right"
                                >
                                  <Link className="dropdown-item" to={`/user/${user.id}`}>
                                    <i className="ft-plus-circle primary" />
                                    User details
                                  </Link>
                                  {profile && profile.isAdmin === true ? (
                                    <Link className="dropdown-item" to={`/user/${user.id}/edit`}>
                                      <i className="ft-edit-2" />
                                      Edit user
                                    </Link>
                                  ) : null}
                                  {profile && profile.isSuperAdmin === true ? (
                                    <button
                                      onClick={() => {
                                        const decrementUsers = firestore.FieldValue.increment(-1);
                                        const usersCountRef = firestore.collection('settings').doc(profile.settingsId);

                                        firestore.delete({ collection: `users`, doc: user.id });
                                        const batch = firestore.batch();
                                        batch.set(usersCountRef, { usersCount: decrementUsers }, { merge: true });
                                        batch.commit();
                                      }}
                                      type="submit"
                                      className="btn btn-link"
                                    >
                                      <i className="ft-trash-2" />
                                      Delete user
                                    </button>
                                  ) : null}
                                </span>
                              </span>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TableUsers;
