/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import moment from 'moment';
// import 'moment/locale/hr';

const ProjectTaskCard = (task, projectId, firestore, history) => (
  <div className="row">
    <div className="col-xl-12 col-lg-12">
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">{`Task: ${task.task.title}`}</h2>
          <div className="heading-elements">
            <ul className="list-inline mb-0">
              <li style={{ padding: '0 5px' }}>
                <i className="ft-edit" />
              </li>
              <li style={{ padding: '0 5px' }}>
                <i className="ft-x" />
              </li>
              <li>
                <button
                  onClick={() =>
                    firestore
                      .delete({ collection: `projects/${projectId}/tasks`, doc: task.id })
                      .then(() => history.push(`/projects/${projectId}`))
                  }
                  type="submit"
                  className="btn btn-link text-light"
                >
                  <i className="ft-trash-2" />
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-content collapse show">
          <div className="card-body">
            <div className="card-text">
              <p>
                <span className="text-bold-600 mt-2">{moment(task.task.createdAt.toDate()).fromNow()}</span>
              </p>
              <p>{task.task.body}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProjectTaskCard;
