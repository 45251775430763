/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import { Spring, config } from 'react-spring/renderprops';

const ProjectsCard = ({ projects }) => (
  <>
    {projects &&
      projects.map(
        ({
          title,
          description,
          isPrivate,
          id,
          startDate,
          endDate,
          authorCompany,
          authorFirstName,
          authorLastName,
          authorPhoto,
          tasksCount,
          tasksActiveCount,
          tasksFinishedCount,
        }) => {
          const tasksProgress = (((tasksCount - tasksActiveCount) * 100) / tasksCount).toFixed();

          return (
            <Spring config={config.gentle} from={{ opacity: 0, marginTop: 30 }} to={{ opacity: 1, marginTop: 0 }}>
              {props => (
                <div style={props} className="col-md-4 float-left" key={id}>
                  <div
                    className={classnames('card', {
                      'bg-dark text-white': isPrivate,
                    })}
                  >
                    <div
                      className={classnames('card-header', {
                        'bg-dark text-white': isPrivate,
                      })}
                    >
                      <h4 className="card-title">
                        <Link
                          to={`/project/${id}`}
                          className={classnames('card-title', {
                            'text-danger': isPrivate,
                          })}
                        >
                          {title}
                        </Link>
                      </h4>
                      <span className="text-medium-1 danger line-height-2 text-uppercase">{authorCompany}</span>
                      <div className="heading-elements">
                        <ul className="list-inline mb-0 display-block">
                          <li>
                            <Link
                              to={`/project/${id}`}
                              className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right"
                            >
                              {isPrivate ? 'Go to private project' : 'Go to project'}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-content collapse show">
                      <div className="card-body pt-0 pb-1">
                        <div>{description}</div>
                        <hr style={isPrivate ? { borderTop: '1px solid white' } : null} />

                        <div className="row mb-1">
                          <div className="col-6 col-sm-3 col-md-6 col-lg-3 border-right-blue-grey border-right-lighten-5 text-center">
                            <p className="blue-grey lighten-2 mb-0">Tasks</p>
                            <p className="font-medium-5 text-bold-400">{tasksCount || 0}</p>
                          </div>
                          <div className="col-6 col-sm-3 col-md-6 col-lg-3 border-right-blue-grey border-right-lighten-5 text-center">
                            <p className="blue-grey lighten-2 mb-0">Active</p>
                            <p className="font-medium-5 text-bold-400">{tasksCount - tasksFinishedCount || 0}</p>
                          </div>
                          <div className="col-6 col-sm-3 col-md-6 col-lg-3 border-right-blue-grey border-right-lighten-5 text-center">
                            <p className="blue-grey lighten-2 mb-0">Completed</p>
                            <p className="font-medium-5 text-bold-400">{tasksFinishedCount || 0}</p>
                          </div>
                          <div className="col-6 col-sm-3 col-md-6 col-lg-3 text-center">
                            <p className="blue-grey lighten-2 mb-0">Total</p>
                            <p className="font-medium-5 text-bold-400">
                              {tasksCount > 0 ? (((tasksCount - tasksActiveCount) * 100) / tasksCount).toFixed() : 0}
                              <span>%</span>
                            </p>
                          </div>
                        </div>

                        <h6
                          className={classnames('text-bold-600', {
                            'text-white': isPrivate,
                          })}
                        >
                          Project progress:
                        </h6>
                        <div className="progress progress-sm mt-1 mb-0 box-shadow-2">
                          <div
                            className={classnames('progress-bar', {
                              'bg-gradient-x-danger': tasksProgress <= 33,
                              'bg-gradient-x-primary': tasksProgress > 33,
                              'bg-gradient-x-success': tasksProgress >= 66,
                            })}
                            role="progressbar"
                            style={{ width: `${tasksProgress}%` }}
                            aria-valuenow={tasksProgress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          />
                        </div>
                        <div className="media d-flex">
                          <div className="align-self-center">
                            <h6 className="text-bold-600 mt-2">
                              <span className="danger">
                                <span
                                  className={classnames({
                                    'text-white': isPrivate,
                                    black: !isPrivate,
                                  })}
                                >
                                  Start date:
                                </span>
                                {startDate ? ` ${moment(startDate.toDate()).fromNow()}` : null}
                              </span>
                              <br />
                              <span
                                className={classnames({
                                  'text-white': isPrivate,
                                  black: !isPrivate,
                                })}
                              >
                                End date:
                              </span>
                              <span className="danger">
                                {startDate ? ` ${moment(endDate.toDate()).fromNow()}` : null}
                              </span>
                              <hr />
                            </h6>
                          </div>
                          <div className="media-body text-right mt-2 d-md-block">
                            <span className="text-bold-600 mt-1">{`${authorFirstName} ${authorLastName}`}</span>
                            <ul className="list-unstyled users-list">
                              <li
                                data-toggle="tooltip"
                                data-popup="tooltip-custom"
                                data-original-title={authorFirstName}
                                className="avatar avatar-sm pull-up"
                              >
                                <img className="media-object rounded-circle" src={authorPhoto} alt={authorFirstName} />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Spring>
          );
        }
      )}
  </>
);
export default ProjectsCard;
