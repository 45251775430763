/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

class AddProjectTaskComment extends Component {
  state = {
    title: '',
    body: '',
    createdAt: new Date(),
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { title, body, createdAt } = this.state;
    const { firestore, task, projectId, profile, auth } = this.props;

    firestore
      .collection('projects')
      .doc(projectId)
      .collection('tasks')
      .doc(task.id)
      .collection('comments')
      .add({
        title,
        body,
        createdAt,
        authorFirstName: profile.firstName,
        authorLastName: profile.lastName,
        authorPhoto: profile.avatarURL,
        authorEmail: profile.email,
        authorId: auth.uid,
      })
      .then(() => this.setState({ title: '', body: '' }));
  };

  render() {
    const { title, body } = this.state;

    return (
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">New task comment:</h4>
        </div>
        <div className="card-body">
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label htmlFor="title">title</label>
              <input
                type="text"
                className="form-control"
                name="title"
                id="title"
                minLength="2"
                placeholder="title"
                onChange={this.onChange}
                value={title}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="body">body</label>
              <textarea
                className="form-control"
                rows="3"
                name="body"
                id="body"
                minLength="2"
                placeholder="body"
                onChange={this.onChange}
                value={body}
              />
            </div>
            <button className="btn btn-rounded btn-primary" type="submit">
              Add new task comment
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(AddProjectTaskComment);
