/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import Spinner from '../layout/Spinner';
import 'react-quill/dist/quill.snow.css';

class EditProjectTask extends Component {
  constructor(props) {
    super(props);
    this.titleInput = createRef();
    this.bodyInput = createRef();
  }

  onDeleteClick = () => {
    const { project, task, firestore, history } = this.props;
    firestore
      .delete({
        collection: 'projects',
        doc: project.id,
        subcollections: [{ collection: 'tasks', doc: task.id }],
      })
      .then(history.push('/projects'));
  };

  onSubmit = e => {
    e.preventDefault();
    const { project, task, firestore, history } = this.props;
    const updatedTask = {
      title: this.titleInput.current.value,
    };

    firestore
      .update(
        { collection: 'projects', doc: project.id, subcollections: [{ collection: 'tasks', doc: task.id }] },
        updatedTask
      )
      .then(history.push(`/project/${project.id}`));
  };

  render() {
    const { project, task, firestore } = this.props;

    if (task) {
      console.log(project, task);
      return (
        <>
          <Helmet>
            <body
              className="horizontal-layout horizontal-menu 2-columns menu-expanded"
              data-open="hover"
              data-menu="horizontal-menu"
              data-col="2-columns"
              data-color="bg-gradient-x-pink-orange"
            />
          </Helmet>
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-4 col-12 mb-2">
              <h3 className="content-header-title">{`Project: ${task.title}`}</h3>
            </div>
            <div className="content-header-right col-md-8 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/projects">Projects</a>
                    </li>
                    <li className="breadcrumb-item active">{task.title}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">nova poruka</div>
            <div className="card-body">
              <div dangerouslySetInnerHTML={{ __html: task.body }} />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="title">title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="title"
                    minLength="2"
                    placeholder="title"
                    ref={this.titleInput}
                    defaultValue={task.title}
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="body">body</label>
                  <input
                    type="text"
                    className="form-control"
                    name="body"
                    id="body"
                    minLength="2"
                    placeholder="body"
                    ref={this.bodyInput}
                    defaultValue={task.body}
                  />
                </div> */}
                <div className="form-group">
                  <ReactQuill
                    defaultValue={task.body}
                    modules={EditProjectTask.modules}
                    formats={EditProjectTask.formats}
                    theme="snow"
                    onChange={e => {
                      const updatedBody = {
                        body: DOMPurify.sanitize(e),
                      };

                      firestore.update(
                        {
                          collection: 'projects',
                          doc: project.id,
                          subcollections: [{ collection: 'tasks', doc: task.id }],
                        },
                        updatedBody
                      );
                    }}
                  />
                </div>
                <button className="btn btn-rounded btn-primary" type="submit">
                  Update project
                </button>
                {'  '}
                <button onClick={this.onDeleteClick} className="btn btn-rounded btn-danger" type="submit">
                  Delete project
                </button>
              </form>
            </div>
          </div>
        </>
      );
    }
    return <Spinner />;
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
EditProjectTask.modules = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
EditProjectTask.formats = [
  // 'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'projects',
      doc: props.match.params.id,
      storeAs: 'project',
    },
    {
      collection: 'projects',
      doc: props.match.params.id,
      subcollections: [{ collection: 'tasks', doc: props.match.params.tid }],
      storeAs: 'task',
    },
  ]),
  connect(({ firestore: { ordered } }, state, props) => ({
    project: ordered.project && ordered.project[0],
    task: ordered.task && ordered.task[0],
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(EditProjectTask);
