/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';
import 'firebase/storage';

import FileUploader from 'react-firebase-file-uploader';

import Spinner from '../layout/Spinner';
import AppWarning from '../layout/AppWarning';
import '../projects/DateTimePicker.css';

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordUpdateMessage: null,
      newUserPassword: '',
      newUserPasswordConfirm: '',
      isUploading: false,
      progress: 0,
      avatarURL: '',
    };
    this.firstNameInput = createRef();
    this.lastNameInput = createRef();
    this.emailInput = createRef();
    this.privateEmailInput = createRef();
    this.isAdminInput = createRef();
    this.inCompanyInput = createRef();
    this.avatarURLInput = createRef();
    this.privatePhoneInput = createRef();
    this.companyPhoneInput = createRef();
    this.startCompanyDateInput = createRef();
    this.endCompanyDateInput = createRef();
    this.companySectionInput = createRef();
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { user, firestore, history } = this.props;
    const { isUploading, progress, avatarURL } = this.state;
    const updatedUser = {
      firstName: this.firstNameInput.current.value,
      lastName: this.lastNameInput.current.value,
      email: this.emailInput.current.value,
      privateEmail: this.privateEmailInput.current.value,
      privatePhone: this.privatePhoneInput.current.value,
      companyPhone: this.companyPhoneInput.current.value,
      // startCompanyDate: this.startCompanyDateInput.current.state.selectedDate._d,
      // endCompanyDate: this.endCompanyDateInput.current.state.selectedDate._d,
      companySection: this.companySectionInput.current.value,
      isAdmin: this.isAdminInput.current.checked,
      inCompany: this.inCompanyInput.current.checked,
      avatarURL: avatarURL || this.avatarURLInput.current.value,
      isUploading,
      progress,
    };

    firestore.update({ collection: 'users', doc: user.id }, updatedUser).then(history.push('/users'));
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    const { firebase } = this.props;
    this.setState({ progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('photos')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        // console.log(url);
        this.setState({ avatarURL: url });
      });
  };

  render() {
    const { user, firebase, settings, profile } = this.props;
    const { isUploading, progress, passwordUpdateMessage, newUserPassword, newUserPasswordConfirm } = this.state;

    if (user && profile && profile.isAdmin) {
      return (
        <>
          <Helmet>
            <body
              className="horizontal-layout horizontal-menu 2-columns menu-expanded"
              data-open="hover"
              data-menu="horizontal-menu"
              data-col="2-columns"
              data-color="bg-gradient-x-pink-orange"
            />
          </Helmet>
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-4 col-12 mb-2">
              <h3 className="content-header-title">{`Edit profile: ${user.firstName} ${user.lastName}`}</h3>
            </div>
            <div className="content-header-right col-md-8 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/users">People</a>
                    </li>
                    <li className="breadcrumb-item active">{`${user.firstName} ${user.lastName}`}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {!isLoaded(user) ? (
            <Spinner />
          ) : isEmpty(user) ? (
            'User details are not available'
          ) : (
            <div className="row">
              <div className="col-md-9">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={this.onSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="firstName">
                              <strong>First name:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              id="firstName"
                              placeholder="First name"
                              ref={this.firstNameInput}
                              defaultValue={user.firstName}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="lastName">
                              <strong>Last name:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              id="lastName"
                              placeholder="Last name"
                              ref={this.lastNameInput}
                              defaultValue={user.lastName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="email">
                              <strong>Company Email:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="email"
                              ref={this.emailInput}
                              defaultValue={user.email}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="privateEmail">
                              <strong>Private Email:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="privateEmail"
                              id="privateEmail"
                              placeholder="privateEmail"
                              ref={this.privateEmailInput}
                              defaultValue={user.privateEmail}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="companyPhone">
                              <strong>Company Phone:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="companyPhone"
                              id="companyPhone"
                              placeholder="companyPhone"
                              ref={this.companyPhoneInput}
                              defaultValue={user.companyPhone}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="privatePhone">
                              <strong>Private Phone:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="privatePhone"
                              id="privatePhone"
                              placeholder="privatePhone"
                              ref={this.privatePhoneInput}
                              defaultValue={user.privatePhone}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="startCompanyDate">
                              <strong>Start Date in Company:</strong>
                            </label>
                            <Datetime
                              id="startCompanyDate"
                              ref={this.startCompanyDateInput}
                              defaultValue={(user.startCompanyDate && user.startCompanyDate.toDate()) || ''}
                              inputProps={{ placeholder: 'Select start date in company...' }}
                              onChange={this.handleStartCompanyDate}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="endCompanyDate">
                              <strong>End Date in Company:</strong>
                            </label>
                            <div className="input-group">
                              <Datetime
                                id="endCompanyDate"
                                ref={this.endCompanyDateInput}
                                defaultValue={(user.endCompanyDate && user.endCompanyDate.toDate()) || ''}
                                inputProps={{ placeholder: 'Select end date in company...' }}
                              />
                              <div className="input-group-append">
                                <button className="btn btn-outline-primary btn-danger" type="button" onClick={() => {}}>
                                  X
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="image">
                              <strong>Image:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="image"
                              id="image"
                              placeholder="image"
                              ref={this.avatarURLInput}
                              defaultValue={user.avatarURL}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="companySection">
                            <strong>Company Sections:</strong>
                          </label>
                          <fieldset className="form-group position-relative">
                            <select
                              className="form-control"
                              name="companySection"
                              id="companySection"
                              placeholder="companySection"
                              ref={this.companySectionInput}
                              defaultValue={user.companySection}
                            >
                              <option selected>Choose section</option>
                              {settings &&
                                settings.companySections.map(setting => (
                                  <option selected={setting === user.companySection} value={setting} key={setting}>
                                    {setting}
                                  </option>
                                ))}
                            </select>
                          </fieldset>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            className="form-group form-check p-1 text-center"
                            style={{ backgroundColor: '#efefef', border: '1px solid #ccc' }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="inCompany"
                              id="inCompany"
                              ref={this.inCompanyInput}
                              defaultChecked={!!user.inCompany}
                            />
                            <label className="form-check-label" htmlFor="inCompany">
                              <strong>User is in company:</strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            className="form-group form-check p-1 text-center"
                            style={{ backgroundColor: '#efefef', border: '1px solid #ccc' }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="isAdmin"
                              id="isAdmin"
                              ref={this.isAdminInput}
                              defaultChecked={!!user.isAdmin}
                            />
                            <label className="form-check-label" htmlFor="isAdmin">
                              <strong>User is Admin:</strong>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">(available spot)</div>
                      </div>

                      {/* <div className="form-row">
                      <div className="col-sm-12 mb-3">
                        <label htmlFor="fileName" className="btn btn-rounded btn-primary">
                          Upload new user image (800x800px) &rarr;
                          <FileUploader
                            hidden
                            accept="image/*"
                            name="fileName"
                            id="fileName"
                            randomizeFilename
                            storageRef={firebase.storage().ref('photos')}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            maxWidth="800"
                            maxHeight="800"
                            className="form-control"
                          />
                        </label>
                        {isUploading && <span>{` Progress: ${progress}%`}</span>}
                      </div>
                    </div> */}

                      <button className="btn btn-rounded btn-primary btn-lg" type="submit">
                        Update details!
                      </button>
                    </form>
                  </div>
                </div>
                <form className="form-horizontal">
                  <div className="card box-shadow-1">
                    <div className="card-content p-2">
                      <div className="col-md-12 p-0 d">
                        {passwordUpdateMessage ? (
                          <span className="text-danger mb-1">{`⚠️ ${passwordUpdateMessage} ⚠️`}</span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="row">
                        <strong className="container pt-1 pb-1">New password:</strong>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              name="newUserPassword"
                              id="newUserPassword"
                              minLength="2"
                              placeholder="Enter new password..."
                              value={newUserPassword}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              name="newUserPasswordConfirm"
                              id="newUserPasswordConfirm"
                              minLength="2"
                              placeholder="Confirm new password..."
                              value={newUserPasswordConfirm}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            className="btn btn-info"
                            onClick={e => {
                              e.preventDefault();

                              const currentUser = firebase.auth().currentUser;

                              currentUser
                                .updatePassword(newUserPassword)
                                .then(() => {
                                  this.setState({ passwordUpdateMessage: 'User password updated successfuly!' });
                                  console.log('User password updated successfuly!');
                                })
                                .catch(error => {
                                  console.log(error);
                                  this.setState({ passwordUpdateMessage: error.message });
                                  // return errorMsg;
                                });
                            }}
                            type="button"
                            disabled={
                              newUserPassword !== newUserPasswordConfirm ||
                              newUserPassword.length === 0 ||
                              newUserPasswordConfirm.length === 0
                            }
                          >
                            Change password!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-3">
                <div className="card box-shadow-1">
                  <div className="card-content">
                    <img className="img-fluid card-img" src={user.avatarURL} alt="Card" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-12 mb-3">
                    <label htmlFor="fileName" className="btn btn-rounded btn-primary">
                      Upload new user image (800x800px) &rarr;
                      <FileUploader
                        hidden
                        accept="image/*"
                        name="fileName"
                        id="fileName"
                        randomizeFilename
                        storageRef={firebase.storage().ref('photos')}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        maxWidth="800"
                        maxHeight="800"
                        className="form-control"
                      />
                    </label>
                    {isUploading && <span>{` Progress: ${progress}%`}</span>}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
    return <AppWarning data="user" dataId={user && user.id} />;
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    settings: state.firestore.ordered.settings && state.firestore.ordered.settings[0],
    user: state.firestore.ordered.user && state.firestore.ordered.user[0],
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  })),
  firestoreConnect(props => [
    {
      collection: 'users',
      storeAs: 'user',
      doc: props.match.params.id,
    },
    {
      collection: 'settings',
    },
  ])
)(EditUser);
