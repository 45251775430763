/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import { notifyUser } from '../../store/actions/notifyActions';
import Alert from '../layout/Alert';

class Login extends Component {
  state = {
    email: '',
    password: '',
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onSubmit = e => {
    e.preventDefault();

    const { firebase, history, notifyUser } = this.props;
    const { email, password } = this.state;

    firebase
      .login({
        email,
        password,
      })
      .then(() => history.push('/'))
      .catch(err => notifyUser('bad login', 'error'));
  };

  render() {
    const { email, password } = this.state;
    const { message, messageType } = this.props.notify;

    let projectName = '';

    if (process.env.NODE_ENV === 'production') {
      projectName = process.env.REACT_APP_PRODUCTION_PROJECT_NAME;
    } else {
      projectName = process.env.REACT_APP_DEVELOPMENT_PROJECT_NAME;
    }
    // const { profile } = this.props;
    // console.log(profile);

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{projectName}</title>
          <body
            className="horizontal-layout horizontal-menu 1-column  bg-full-screen-image menu-expanded blank-page blank-page"
            data-open="hover"
            data-menu="horizontal-menu"
            data-color="bg-gradient-x-purple-blue"
            data-col="1-column"
          />
        </Helmet>
        <div className="app-content content">
          <div className="content-wrapper">
            <div className="content-wrapper-before" />
            <div className="content-header row" />
            <div className="content-body">
              <section className="flexbox-container">
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <div className="col-md-4 col-10 box-shadow-2 p-0">
                    <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div className="card-header border-0">
                        {message ? <Alert message={message} messageType={messageType} /> : null}
                        <div className="text-center mb-1">
                          <h3>{`${projectName} Dashboard`}</h3>
                          {/* <img src="/app-assets/images/logo/logo.png" alt="branding logo" /> */}
                        </div>
                        <div className="font-large-1  text-center">Login</div>
                      </div>
                      <div className="card-content">
                        <div className="card-body">
                          <form className="form-horizontal" onSubmit={this.onSubmit}>
                            <fieldset className="form-group position-relative has-icon-left">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                aria-describedby="emailHelp"
                                placeholder="Email"
                                value={email}
                                onChange={this.onChange}
                                required
                              />
                              <div className="form-control-position">
                                <i className="ft-user" />
                              </div>
                            </fieldset>
                            <fieldset className="form-group position-relative has-icon-left">
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={this.onChange}
                                required
                              />
                              <div className="form-control-position">
                                <i className="ft-lock" />
                              </div>
                            </fieldset>
                            <div className="form-group row">
                              <div className="col-md-6 col-12 text-center text-sm-left" />
                            </div>
                            <div className="form-group text-center">
                              <button
                                type="submit"
                                className="btn round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1"
                              >
                                Login
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(
    (state, props) => ({
      profile: state.firebase.profile,
      auth: state.firebase.auth,
      notify: state.notify,
    }),
    { notifyUser }
  )
)(Login);
