/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Spring, config } from 'react-spring/renderprops';

class AddProjectTask extends Component {
  state = {
    title: '',
    description: '',
    body: '',
    orderNo: '',
    taskTag: '',
    taskFinished: false,
    createdAt: new Date(),
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { title, description, body, orderNo, taskFinished, createdAt, taskTag } = this.state;
    const { firestore, project } = this.props;

    const incrementTasks = firestore.FieldValue.increment(1);
    const tasksCountRef = firestore.collection('projects').doc(project.id);

    firestore
      .collection('projects')
      .doc(project.id)
      .collection('tasks')
      .add({
        title,
        description,
        body,
        orderNo: parseFloat(orderNo),
        taskDeleted: false,
        taskFinished,
        createdAt,
        taskTag,
      })
      .then(() => this.setState({ title: '', orderNo: '', description: '', taskTag: '' }));

    const batch = firestore.batch();
    batch.set(tasksCountRef, { tasksCount: incrementTasks }, { merge: true });
    batch.set(tasksCountRef, { tasksActiveCount: incrementTasks }, { merge: true });
    batch.commit();
  };

  render() {
    const { title, description, orderNo, taskTag } = this.state;
    const { settings } = this.props;

    return (
      <Spring
        config={config.gentle}
        from={{ opacity: 0, marginTop: 60 }}
        to={{ opacity: 1, marginTop: 0 }}
        delay={300}
        duration={900}
      >
        {props => (
          <div className="card" style={props}>
            <div className="card-header">
              <h5>
                <strong>Add new task:</strong>
              </h5>
            </div>
            <div className="card-body">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="title">
                    <strong>Title:</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="title"
                    minLength="2"
                    placeholder="title"
                    onChange={this.onChange}
                    value={title}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="orderNo">
                    <strong>Position #:</strong>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="orderNo"
                    id="orderNo"
                    minLength="1"
                    placeholder="order number"
                    onChange={this.onChange}
                    value={orderNo}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="taskTag">
                    <strong>Tag:</strong>
                  </label>
                  <select
                    className="form-control"
                    name="taskTag"
                    id="taskTag"
                    placeholder="taskTag"
                    onChange={this.onChange}
                  >
                    <option selected>Choose tag</option>
                    {settings &&
                      settings.taskTags.map(setting => (
                        <option value={setting.taskTag} key={taskTag}>
                          {setting}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="body">
                    <strong>Description:</strong>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="description..."
                    type="text"
                    name="description"
                    id="description"
                    minLength="2"
                    onChange={this.onChange}
                    value={description}
                  />
                </div>
                <button className="btn btn-rounded btn-primary" type="submit">
                  Create new task &rarr;
                </button>
              </form>
            </div>
          </div>
        )}
      </Spring>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    settings: state.firestore.ordered.settings && state.firestore.ordered.settings[0],
  })),
  firestoreConnect(props => [
    {
      collection: 'settings',
      where: [['accountName', '==', props.profile.isLoaded && props.profile.companyName]],
      storeAs: 'settings',
    },
  ])
)(AddProjectTask);
