/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, createRef } from 'react';
import 'firebase/storage';
import FileUploader from 'react-firebase-file-uploader';

class ProjectFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      progress: 0,
    };
    this.titleInput = createRef();
    this.bodyInput = createRef();
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    const { firebase, project, firestore } = this.props;

    this.setState({ progress: 100, isUploading: false });

    firebase
      .storage()
      .ref('files')
      .child(filename)
      .getDownloadURL()
      .then(url => {
        firestore.update(
          { collection: 'projects', doc: project.id },
          {
            filesURL: firestore.FieldValue.arrayUnion(url),
          }
        );
      });
  };

  render() {
    const { project, firestore, firebase } = this.props;
    const { isUploading, progress } = this.state;

    return (
      <div style={{ border: '1px solid #ccc' }}>
        <div className="card-content collapse show">
          <div className="card-body">
            <h4 className="card-title">Project Files:</h4>

            <div className="row">
              <div className="col-md-4">
                <div className="form-row">
                  <label htmlFor="fileName" className="btn btn-rounded btn-primary btn-block">
                    Upload new file &rarr;
                    <FileUploader
                      hidden
                      accept="image/*, .pdf, .doc, .docx, .xls"
                      name="fileName"
                      id="fileName"
                      ref={this.fileName}
                      storageRef={firebase.storage().ref('files')}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                      className="form-control"
                    />
                  </label>
                  {isUploading && <span>{` Upload progress: ${progress}%`}</span>}
                </div>
              </div>
              <div className="col-md-8">
                {project.filesURL &&
                  project.filesURL.map(file => {
                    const offset = file.indexOf('.appspot.com/o/files%2F');
                    return (
                      <div className="row pb-1" key={file}>
                        <a
                          href={file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn col-md-8 float-left btn-social  mr-1  btn-outline-facebook"
                        >
                          <i className="ft-download" />
                          {`Download file: ${
                            file
                              .slice(offset + 23)
                              .replace(/%20/g, ' ')
                              .split('?')[0]
                          }`}
                        </a>
                        <button
                          onClick={e => {
                            e.preventDefault();
                            const onDeleteFile = {
                              filesURL: firestore.FieldValue.arrayRemove(file),
                            };

                            firestore.update({ collection: 'projects', doc: project.id }, onDeleteFile);
                          }}
                          className="btn btn-md-4 btn-link btn-danger"
                          type="submit"
                        >
                          Delete file!
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectFiles;
