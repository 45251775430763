/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-indent */
/* eslint-disable prettier/prettier */
import React from 'react';

const Footer = () => (
  <footer
className="footer footer-static footer-light navbar-shadow"
style={{
    position: 'fixed', bottom: 0, left: 0, right: 0}}
  >
    <div className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
      <span className="float-md-left d-block d-md-inline-block">
        2019 &copy; Copyright
        <a
          className="text-bold-800 grey darken-2"
          href="https://www.businessdna.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          BusinessDNA.net
        </a>
      </span>
      <ul className="list-inline float-md-right d-block d-md-inline-blockd-none d-lg-block mb-0">
        <li className="list-inline-item">
          Version 0.9.5
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
