import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

import Projects from './components/projects/Projects';

class App extends Component {
  state = {};

  render() {
    return (
      <div className="App">
        {/* <div className="row">
          <div className="col-6">
            <StatProjects projects={projects} userProjects={userProjects} />
          </div>
          <div className="col-6">
            <StatUsers />
          </div>
        </div> */}
        <Projects />
      </div>
    );
  }
}

export default App;
