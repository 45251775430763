/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { firestoreConnect, firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FileUploader from 'react-firebase-file-uploader';

import 'firebase/storage';

import Spinner from '../layout/Spinner';

class Register extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    password: '',
    fileName: '',
    avatarURL: '',
    isUploading: false,
    progress: 0,
    isAdmin: false,
    inCompany: true,
  };

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  onSubmit = e => {
    e.preventDefault();

    const { firestore, profile } = this.props;

    const incrementUsers = firestore.FieldValue.increment(1);
    const usersCountRef = firestore.collection('settings').doc(profile.settingsId);

    const newUser = { ...this.state };
    const { firstName, lastName, email, isAdmin, inCompany, fileName, avatarURL } = this.state;
    const {
      firebase,
      history,
      settings,
      profile: { companyName },
    } = this.props;

    firebase
      .createUser(newUser, {
        firstName,
        lastName,
        email,
        companyName,
        isAdmin,
        inCompany,
        fileName,
        avatarURL,
        settingsId: settings.id,
      })
      .then(() => history.push('/users'));

    const batch = firestore.batch();
    batch.set(usersCountRef, { usersCount: incrementUsers }, { merge: true });
    batch.commit();
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    const { firebase } = this.props;
    this.setState({ fileName: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('photos')
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ avatarURL: url }));
  };

  render() {
    const { firstName, lastName, email, password, isUploading, progress, avatarURL } = this.state;
    const {
      firebase,
      profile,
      profile: { companyName },
    } = this.props;

    console.log(profile);
    // console.log(settings);

    return (
      <>
        {!isLoaded(profile) ? (
          <Spinner />
        ) : isEmpty(profile) ? (
          'Register module not available!'
        ) : (
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5>
                  <strong>Add new person!</strong>
                </h5>
                <hr />

                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label htmlFor={firstName}>
                      <strong>First name:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      id="firstName"
                      placeholder="Enter first name..."
                      required
                      value={firstName}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={lastName}>
                      <strong>Last name:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      id="lastName"
                      placeholder="Enter last name..."
                      required
                      value={lastName}
                      onChange={this.onChange}
                    />
                  </div>
                  {profile.isAdmin ? (
                    <div className="form-group">
                      <label htmlFor={companyName}>
                        <strong>Company:</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        id="companyName"
                        placeholder={companyName}
                        value={companyName}
                        onChange={this.onChange}
                        required
                        disabled
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="form-group">
                    <label htmlFor={email}>
                      <strong>Email:</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter email address..."
                      required
                      value={email}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={password}>
                      <strong>Password:</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="Enter password..."
                      required
                      value={password}
                      onChange={this.onChange}
                    />
                  </div>
                  <label htmlFor={firstName}>
                    <strong>Upload photo:</strong>
                  </label>
                  {avatarURL && (
                    <img src={avatarURL} alt="" width="200" className="img-fluid rounded-circle float-right" />
                  )}
                  <div className="form-row">
                    <label htmlFor="fileName" className="btn btn-rounded btn-primary">
                      Upload user photo (800x800px) &rarr;
                      <FileUploader
                        hidden
                        accept="image/*"
                        name="fileName"
                        id="fileName"
                        randomizeFilename
                        storageRef={firebase.storage().ref('photos')}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        maxWidth="800"
                        maxHeight="800"
                        className="form-control"
                      />
                    </label>
                    {isUploading && <span>{` Progress: ${progress}%`}</span>}
                  </div>
                  <button className="btn btn-lg btn-rounded btn-primary btn-block mt-1" type="submit">
                    Add new person!
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    settings: state.firestore.ordered.settings && state.firestore.ordered.settings[0],
  })),
  firestoreConnect(props => [
    {
      collection: 'settings',
      where: [['accountName', '==', props.profile.isLoaded && props.profile.companyName]],
      storeAs: 'settings',
    },
  ])
)(withRouter(Register));
