/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';

class ReorderProjectsItem extends Component {
  constructor(props) {
    super(props);
    const { tasks, firestore } = this.props;
    console.log(tasks);
    this.state = {
      items: tasks,
    };

    this.onDragStart = (e, index) => {
      this.draggedItem = this.state.items[index];

      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.target.parentNode);
      e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    this.onDragOver = index => {
      const draggedOverItem = this.state.items[index];

      // if the item is dragged over itself, ignore
      if (this.draggedItem === draggedOverItem) {
        return;
      }

      // filter out the currently dragged item
      // eslint-disable-next-line react/no-access-state-in-setstate
      const items = this.state.items.filter(item => item !== this.draggedItem);

      // add the dragged item after the dragged over item
      items.splice(index, 0, this.draggedItem);

      const updatedTaskOrderNo = {
        orderNo: items.indexOf(this.draggedItem),
        // orderNo: parseFloat(newIndex),
      };
      // firestore.update(
      //   { collection: `projects/FVp4Dg8OTr67q0ecVyIA/tasks`, doc: this.draggedItem.id },
      //   updatedTaskOrderNo
      // );

      // console.log(items.indexOf(this.draggedItem));
      // console.log(this.draggedItem.id);
    };

    this.onDragEnd = () => {
      this.draggedIdx = null;
      const { items } = this.state;
      this.setState({ items });
      firestore.add({ collection: `projects/FVp4Dg8OTr67q0ecVyIA/tasks` }, { ...this.state, createdAt: new Date() });
    };
  }

  render() {
    // const { tasks, firestore, profile } = this.props;
    const { items } = this.state;

    return (
      <>
        {items &&
          items.sort().map((item, idx) => (
            <li key={item.id} onDragOver={() => this.onDragOver(idx)}>
              <span
                className="drag"
                draggable
                onDragStart={e => this.onDragStart(e, idx)}
                onDragEnd={this.onDragEnd}
                style={{ float: 'left', display: 'inline' }}
              >
                <span>🍎</span>
              </span>
              <span className="content">{item.title}</span>
            </li>
          ))}
      </>
    );
  }
}

export default ReorderProjectsItem;
