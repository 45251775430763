import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

import Spinner from '../layout/Spinner';
import ProjectTaskCard from './ProjectTaskCard';

class ProjectTaskComment extends Component {
  state = {};

  render() {
    const { task } = this.props;

    if (task) {
      return (
        <>
          <ProjectTaskCard task={task} />
        </>
      );
    }
    return <Spinner />;
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'projects',
      doc: props.match.params.id,
      subcollections: [{ collection: 'tasks', doc: props.match.params.tid }],
      storeAs: 'task',
    },
  ]),
  connect(({ firestore: { ordered } }, state) => ({
    task: ordered.task && ordered.task[0],
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(ProjectTaskComment);
