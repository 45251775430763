import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

import Spinner from '../layout/Spinner';

class UserComments extends Component {
  state = {};

  render() {
    const { comments } = this.props;

    return (
      <div>
        <div className="container">
          {/* <h1>hello from users comments</h1> */}
          {/* <h3>{user.id}</h3> */}
          {/* <h3>{user && user.comments.length}</h3> */}
          <h3>
            {/* mirko:----- */}
            {/* {comments && comments.length} */}
          </h3>
          <div className="row">
            <div>
              {comments ? (
                comments.map(usr => (
                  <div key={usr.title}>
                    {usr.title}
                    ---
                    {usr.body}
                    {' • '}
                    <br />
                    <hr />
                  </div>
                ))
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: `users/${props.user.id}/comments`,
      storeAs: 'comments',
      limit: 10,
      orderBy: ['createdAt', 'desc'],
    },
  ]),
  connect((state, props) => ({
    comments: state.firestore.ordered.comments,
  }))
)(UserComments);
