/* eslint-disable react/destructuring-assignment */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

import Spinner from '../layout/Spinner';

import AddProjectTaskComment from './AddProjectTaskComment';
import ProjectTaskComments from './ProjectTaskComments';
import ProjectTaskCardEditable from './ProjectTaskCardEditable';

class ProjectTask extends Component {
  state = {};

  render() {
    const { task, project, firestore } = this.props;

    if (task) {
      return (
        <>
          <Helmet>
            <body
              className="horizontal-layout horizontal-menu 2-columns menu-expanded"
              data-open="hover"
              data-menu="horizontal-menu"
              data-col="2-columns"
              data-color="bg-gradient-x-pink-orange"
            />
          </Helmet>
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <h3 className="content-header-title">
                <Link to={`/project/${project.id}/`}>
                  <span style={{ color: '#ee0979' }}>&larr; go back to project:</span>
                  <span style={{ color: '#fff' }}>{` ${project.title}`}</span>
                </Link>
                {/* {` ${task.title}`} */}
              </h3>
            </div>
            <div className="content-header-right col-md-3 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/projects/">Projects</Link>
                    </li>
                    {/* <li className="breadcrumb-item">
                      <Link to={`/project/${project.id}/`}>{project.title}</Link>
                    </li> */}
                    {/* <li className="breadcrumb-item active">
                      <Link to={`/project/${project.id}/task/${task.id}`}>{task.title}</Link>
                    </li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <ProjectTaskCardEditable
                task={task}
                projectId={this.props.match.params.id}
                taskId={this.props.match.params.tid}
                firestore={firestore}
              />
              <ProjectTaskComments
                task={task}
                projectId={this.props.match.params.id}
                taskId={this.props.match.params.tid}
              />
            </div>
            <div className="col-md-4">
              <AddProjectTaskComment
                task={task}
                projectId={this.props.match.params.id}
                taskId={this.props.match.params.tid}
              />
            </div>
          </div>
        </>
      );
    }
    return <Spinner />;
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'projects',
      doc: props.match.params.id,
      storeAs: 'project',
    },
    {
      collection: 'projects',
      doc: props.match.params.id,
      subcollections: [{ collection: 'tasks', doc: props.match.params.tid }],
      storeAs: 'task',
    },
  ]),
  connect(({ firestore: { ordered } }, state, props) => ({
    task: ordered.task && ordered.task[0],
    project: ordered.project && ordered.project[0],
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(ProjectTask);
