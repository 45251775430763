/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import 'firebase/storage';
import 'firebase/functions';

import FileUploader from 'react-firebase-file-uploader';

import Spinner from '../layout/Spinner';

class RegisterAdmin extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    displayName: '',
    password: '',
    fileName: '',
    avatarURL: '',
    isUploading: false,
    progress: 0,
    isAdmin: false,
    inCompany: true,
  };

  onChange = e => this.setState({ [e.target.id]: e.target.value });

  onSubmit = e => {
    e.preventDefault();

    const newUser = { ...this.state };
    const {
      firstName,
      lastName,
      email,
      companyName,
      displayName,
      isAdmin,
      inCompany,
      fileName,
      avatarURL,
    } = this.state;
    const { firebase, history } = this.props;

    firebase
      .createUser(newUser, {
        firstName,
        lastName,
        email,
        companyName,
        displayName,
        // companyName: this.props.profile.companyName,
        isAdmin,
        inCompany,
        fileName,
        avatarURL,
      })
      .then(() => history.push('/users'));
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    const { firebase } = this.props;
    this.setState({ fileName: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('photos')
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ avatarURL: url }));
  };

  render() {
    const { firstName, lastName, email, password, isUploading, progress, avatarURL, companyName } = this.state;
    const { firebase, profile } = this.props;

    return (
      <>
        <Helmet>
          <body
            className="horizontal-layout horizontal-menu 2-columns menu-expanded"
            data-open="hover"
            data-menu="horizontal-menu"
            data-col="2-columns"
            data-color="bg-gradient-x-pink-orange"
          />
        </Helmet>
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Register new admin!</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/settings">Register</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        {!isLoaded(profile) ? (
          <Spinner />
        ) : isEmpty(profile) ? (
          'Register module not available!'
        ) : (
          <div className="container">
            {/* <button
                  className="bnt btn-primary"
                  type="button"
                  onClick={() => {
                    const addCompanyName = functions.httpsCallable('addCompanyName');
                    addCompanyName({ email: 'ipavic@mac.com' }).then(result => console.log(result));
                    console.log('user: ', firebase.auth().currentUser);
                  }}
                >
                  klikni me 'addCompanyName'!!!
                </button>
                
                <button
                  className="bnt btn-danger"
                  type="button"
                  onClick={() => {
                    const addAdminRole = functions.httpsCallable('addAdminRole');
                    addAdminRole({ email: 'ipavic@mac.com' }).then(result => {
                      console.log(result);
                    });
                  }}
                >
                  hello admin!
                </button> */}

            {profile.isAdmin ? (
              <div className="col-xl-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={this.onSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor={firstName}>
                              <strong>First Name:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              id="firstName"
                              placeholder="Enter first name..."
                              required
                              value={firstName}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor={lastName}>
                              <strong>Last Name:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              id="lastName"
                              placeholder="Enter last name..."
                              required
                              value={lastName}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor={companyName}>
                              <strong>Account / Company Name:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="companyName"
                              id="companyName"
                              placeholder="Enter one-word account name..."
                              value={companyName}
                              onChange={this.onChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor={email}>
                              <strong>Email:</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Enter email address..."
                              required
                              value={email}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor={password}>
                              <strong>Password:</strong>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              id="password"
                              placeholder="Enter password..."
                              required
                              value={password}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor={firstName}>
                            <strong>Upload photo:</strong>
                          </label>
                          {avatarURL && (
                            <img src={avatarURL} alt="" width="200" className="img-fluid rounded-circle float-right" />
                          )}
                          <div className="form-row">
                            <label htmlFor="fileName" className="btn btn-rounded btn-primary">
                              Upload user photo (800x800px) &rarr;
                              <FileUploader
                                hidden
                                accept="image/*"
                                name="fileName"
                                id="fileName"
                                randomizeFilename
                                storageRef={firebase.storage().ref('photos')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                                maxWidth="800"
                                maxHeight="800"
                                className="form-control"
                              />
                            </label>
                            {isUploading && <span>{` Progress: ${progress}%`}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button className="btn btn-lg btn-rounded btn-primary btn-block" type="submit">
                          Add new person!
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-xl-12 col-lg-12">
                <div className="card">
                  <div className="card-header h2 danger">Application Error</div>
                  <div className="card-body">
                    <h1 className="">You are not allowed to be here...</h1>
                  </div>
                  <div className="card-footer">Go back...</div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(RegisterAdmin);
