/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import './ProjectTasks.css';

class ProjectTasksActive extends Component {
  constructor(props) {
    super(props);
    this.taskFinishedInput = createRef();
    this.orderNoInput = createRef();
  }

  render() {
    const { tasks, pid, firestore } = this.props;
    const increment = firestore.FieldValue.increment(1);
    const decrement = firestore.FieldValue.increment(-1);

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="card box-shadow-0" style={{ border: '1px solid #ccc' }}>
            <div className="card-content collapse show">
              <div className="card-body">
                <h4 className="card-title">Active tasks:</h4>
                <div className="table-responsive">
                  <div className="container">
                    <div
                      className="row"
                      style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}
                    >
                      <div className="border-0 text-left col-md-1">
                        <span className="list-group-item-heading">#</span>
                      </div>
                      <div className="border-0 text-left col-md-1">
                        <span className="list-group-item-heading">Done</span>
                      </div>
                      <div className="border-0 text-left col-md-8">
                        <span className="list-group-item-heading">Title</span>
                      </div>
                      <div className="border-0 text-center col-md-2">
                        <span className="list-group-item-heading pull-right">Position</span>
                      </div>
                      {/* <div className="border-0 text-right col-md-1">
                        <span className="list-group-item-heading">Delete</span>
                      </div> */}
                    </div>
                    {tasks &&
                      tasks
                        .filter(e => e.taskDeleted === false)
                        .filter(e => e.taskFinished === false)
                        .sort((a, b) => a.orderNo > b.orderNo)
                        .map((task, index) => (
                          <div className="row styleRowActive mb-1" key={task.id}>
                            <div className="border-0 col-md-1">
                              <span className="list-group-item-heading">{index + 1}</span>
                            </div>
                            <div className="text-truncate border-0 text-center col-md-1">
                              <div className="pretty p-default p-curve p-thick">
                                <input
                                  onChange={e => {
                                    const target = e.target;
                                    const value = target.type === 'checkbox' ? target.checked : target.value;

                                    const tasksCountRef = firestore.collection('projects').doc(pid);

                                    const taskRef = firestore
                                      .collection('projects')
                                      .doc(pid)
                                      .collection('tasks')
                                      .doc(task.id);

                                    const updatedTask = {
                                      taskFinished: value,
                                      taskFinishedAt: new Date(),
                                    };

                                    const batch = firestore.batch();
                                    batch.update(taskRef, updatedTask);
                                    batch.set(tasksCountRef, { tasksActiveCount: decrement }, { merge: true });
                                    batch.set(tasksCountRef, { tasksFinishedCount: increment }, { merge: true });
                                    batch.commit();
                                  }}
                                  type="checkbox"
                                  name="taskFinished"
                                  id="taskFinished"
                                  ref={this.taskFinishedInput}
                                  defaultChecked={!!task.taskFinished}
                                />
                                <div className="state p-info">
                                  <label />
                                </div>
                              </div>
                            </div>
                            <div className="text-truncate border-0 task-active text-left col-md-8">
                              <Link to={`/project/${pid}/task/${task.id}`}>
                                <span
                                  className={classnames('list-group-item-heading', {
                                    styleTaskFinished: task.taskFinished,
                                    styleTaskActive: !task.taskFinished,
                                  })}
                                >
                                  {task.title}
                                </span>
                              </Link>
                            </div>
                            <div className="text-truncate border-0 text-center col-md-2">
                              <input
                                onChange={e => {
                                  const target = e.target;
                                  const value = target.value;
                                  this.setState({});

                                  const updatedTaskOrderNo = {
                                    orderNo: parseFloat(value),
                                  };
                                  firestore.update(
                                    { collection: `projects/${pid}/tasks`, doc: task.id },
                                    updatedTaskOrderNo
                                  );
                                }}
                                type="number"
                                className="form-text-input text-center col-md-6 pull-right"
                                name="orderNo"
                                id="orderNo"
                                ref={this.orderNoInput}
                                defaultValue={task.orderNo || ''}
                              />
                            </div>
                            {/* <div className="text-truncate border-0 text-right col-md-1">
                              <span className="badge text-right">
                                <button
                                  onClick={() => {
                                    const tasksCountRef = firestore.collection('projects').doc(pid);

                                    const taskRef = firestore
                                      .collection('projects')
                                      .doc(pid)
                                      .collection('tasks')
                                      .doc(task.id);

                                    const batch = firestore.batch();
                                    batch.delete(taskRef);
                                    batch.set(tasksCountRef, { tasksCount: decrement }, { merge: true });
                                    batch.set(tasksCountRef, { tasksActiveCount: decrement }, { merge: true });
                                    batch.commit();
                                  }}
                                  type="submit"
                                  className="btn btn-link text-light m-0 p-0"
                                >
                                  <i className="ft-trash-2" />
                                </button>
                              </span>
                            </div> */}
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectTasksActive;
