/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import Spinner from '../layout/Spinner';
import ReorderProjectsItem from './ReorderProjectsItem';
import ReorderProjectsCard from './ReorderProjectsCard';

class ProjectsReorder extends Component {
  state = {};

  render() {
    const { tasks, firestore } = this.props;
    console.log(tasks);

    return (
      <main>
        <h3>List of items</h3>
        <ul>
          {!isLoaded(tasks) ? (
            'Loading'
          ) : isEmpty(tasks) ? (
            'Todo list is empty'
          ) : (
            <ReorderProjectsItem tasks={tasks.items} firestore={firestore} />
          )}
        </ul>
      </main>
    );

    // return (
    //   <>
    //     <Helmet>
    //       <body
    //         className="horizontal-layout horizontal-menu 2-columns menu-expanded"
    //         data-open="hover"
    //         data-menu="horizontal-menu"
    //         data-col="2-columns"
    //         data-color="bg-gradient-x-black-lightgreen"
    //       />
    //     </Helmet>
    //     <div className="content-wrapper-before" />
    //     <div className="content-header row">
    //       <div className="content-header-left col-md-4 col-12 mb-2">
    //         <h3 className="content-header-title">
    //           <span className="content-header-title text-uppercase">{`${profile.companyName} `}</span>
    //           Projects
    //         </h3>
    //       </div>
    //       <div className="content-header-right col-md-8 col-12">
    //         <div className="breadcrumbs-top float-md-right">
    //           <div className="breadcrumb-wrapper mr-1">
    //             <ol className="breadcrumb">
    //               <li className="breadcrumb-item">
    //                 <a href="/">Home</a>
    //               </li>
    //               <li className="breadcrumb-item">
    //                 <a href="/projects">Projects</a>
    //               </li>
    //             </ol>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <section>
    //       {projects ? (
    //         <div className="row">
    //           <ReorderProjectsItem projects={projects} firestore={firestore} />
    //         </div>
    //       ) : (
    //         <Spinner />
    //       )}
    //     </section>
    //   </>
    // );
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    tasks: state.firestore.ordered.tasks && state.firestore.ordered.tasks[0],
    // tasks: state.firestore.ordered.tasks,
    auth: state.firebase.auth,
  })),
  firestoreConnect(props => [
    {
      collection: `projects/FVp4Dg8OTr67q0ecVyIA/tasks`,
      storeAs: 'tasks',
      limit: 10,
      // orderBy: ['createdAt', 'asc'],
      orderBy: ['createdAt', 'desc'],
    },
  ])
)(ProjectsReorder);
