import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import ProjectsCard from './ProjectsCard';
import Spinner from '../layout/Spinner';

class Projects extends Component {
  state = {};

  render() {
    const { auth, projects, firestore, companyProjects, profile } = this.props;

    if (profile && profile.isSuperAdmin === true) {
      return (
        <>
          <Helmet>
            <body
              className="horizontal-layout horizontal-menu 2-columns menu-expanded"
              data-open="hover"
              data-menu="horizontal-menu"
              data-col="2-columns"
              data-color="bg-gradient-x-black-lightgreen"
            />
          </Helmet>
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-4 col-12 mb-2">
              <h3 className="content-header-title">
                <span className="content-header-title text-uppercase">{`${profile.companyName} `}</span>
                Projects
              </h3>
            </div>
            <div className="content-header-right col-md-8 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/projects">Projects</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section>
            {projects ? (
              <div className="row">
                <ProjectsCard projects={projects} firestore={firestore} auth={auth} />
                <div className="container">
                  <Link className="btn btn-block btn-bg-gradient-x-purple-blue btn-lg" to="/projects/add">
                    Create new project!
                    <i className="ft-arrow-right pl-1" />
                  </Link>
                </div>
              </div>
            ) : (
              <Spinner />
            )}
          </section>
        </>
      );
    }

    return (
      <>
        <Helmet>
          <body
            className="horizontal-layout horizontal-menu 2-columns menu-expanded"
            data-open="hover"
            data-menu="horizontal-menu"
            data-col="2-columns"
            data-color="bg-gradient-x-black-lightgreen"
          />
        </Helmet>
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">
              <span className="content-header-title text-uppercase">{`${profile.companyName} `}</span>
              Projects
            </h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/projects">Projects</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section>
          {companyProjects ? (
            <div className="row">
              <ProjectsCard
                projects={companyProjects.filter(
                  item => !item.isPrivate || (item.isPrivate && item.authorId === auth.uid)
                )}
                firestore={firestore}
                auth={auth}
              />
              {companyProjects.length === 0 ? (
                <div className="container">
                  <div className="mt-4">
                    <div className="col-xl-3 col-lg-6 col-12">
                      <div className="card bg-gradient-x-purple-red">
                        <div className="card-content">
                          <div className="card-body">
                            <div className="media d-flex">
                              <div className="align-self-top">
                                <i className="icon-users icon-opacity text-white font-large-4 float-left" />
                              </div>
                              <div className="media-body text-white text-left align-self-bottom mt-3">
                                <h3 className="text-white mb-0">No projects found!</h3>
                                <h1 className="d-block mb-1 font-medium-1 text-white">Please, Create new project!!</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link className="btn btn-block btn-bg-gradient-x-purple-blue btn-lg" to="/projects/add">
                      Create new project!
                      <i className="ft-arrow-right pl-1" />
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row">
                    <Link className="btn btn-block btn-bg-gradient-x-purple-blue btn-lg" to="/projects/add">
                      Create new project!
                      <i className="ft-arrow-right pl-1" />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </section>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  connect(state => ({
    projects: state.firestore.ordered.projects,
    companyProjects: state.firestore.ordered.companyProjects,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  })),
  firestoreConnect(props => [
    { collection: 'projects', orderBy: ['createdAt', 'desc'], storeAs: 'projects' },
    {
      collection: 'projects',
      where: [['authorCompany', '==', props.profile.isLoaded && props.profile.companyName]],
      storeAs: 'companyProjects',
    },
  ])
)(Projects);
