/* eslint jsx-a11y/anchor-is-valid: "off" */

/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import moment from 'moment';
import Modal from 'react-modal';

import Spinner from '../layout/Spinner';

class ProjectTaskCardEditable extends Component {
  constructor(props) {
    super(props);
    this.state = { titleEditable: false, descriptionEditable: false };
    this.titleInput = createRef();
    this.bodyInput = createRef();
    this.descriptionInput = createRef();
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  onDeleteActiveTaskClick = () => {
    const { projectId, taskId, firestore, history } = this.props;
    const tasksCountRef = firestore.collection('projects').doc(projectId);

    const increment = firestore.FieldValue.increment(+1);
    const decrement = firestore.FieldValue.increment(-1);

    const taskRef = firestore
      .collection('projects')
      .doc(projectId)
      .collection('tasks')
      .doc(taskId);

    const updatedTask = {
      taskDeleted: true,
      taskDeletedAt: new Date(),
    };

    const batch = firestore.batch();
    batch.update(taskRef, updatedTask);
    batch.set(tasksCountRef, { tasksCount: decrement }, { merge: true });
    batch.set(tasksCountRef, { tasksActiveCount: decrement }, { merge: true });
    batch.set(tasksCountRef, { tasksActiveDeletedCount: increment }, { merge: true });
    batch.commit().then(history.push(`/project/${projectId}`));
  };

  onDeleteFinishedTaskClick = () => {
    const { projectId, taskId, firestore, history } = this.props;
    const tasksCountRef = firestore.collection('projects').doc(projectId);

    const increment = firestore.FieldValue.increment(+1);
    const decrement = firestore.FieldValue.increment(-1);

    const taskRef = firestore
      .collection('projects')
      .doc(projectId)
      .collection('tasks')
      .doc(taskId);

    const updatedTask = {
      taskDeleted: true,
      taskDeletedAt: new Date(),
    };

    const batch = firestore.batch();
    batch.update(taskRef, updatedTask);
    batch.set(tasksCountRef, { tasksCount: decrement }, { merge: true });
    batch.set(tasksCountRef, { tasksFinishedCount: decrement }, { merge: true });
    batch.set(tasksCountRef, { tasksFinishedDeletedCount: increment }, { merge: true });
    batch.commit().then(history.push(`/project/${projectId}`));
  };

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { projectId, taskId, task, firestore, history } = this.props;
    const { titleEditable, descriptionEditable, showModal } = this.state;

    if (task) {
      return (
        <>
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-header" style={{ backgroundColor: '#ee0979' }}>
                  <span className="col-md-11 pull-left pl-0">
                    {titleEditable ? (
                      <textarea
                        rows="3"
                        className="form-control"
                        name="title"
                        id="title"
                        minLength="2"
                        placeholder="title"
                        ref={this.titleInput}
                        defaultValue={task.title}
                        onChange={() => {
                          firestore.update(
                            {
                              collection: 'projects',
                              doc: projectId,
                              subcollections: [{ collection: 'tasks', doc: taskId }],
                            },
                            {
                              title: this.titleInput.current.value,
                            }
                          );
                        }}
                      />
                    ) : (
                      <h2 className="card-title">
                        <span style={{ color: '#000', backgroundColor: '#fff' }}>Task:</span>
                        <span style={{ color: '#fff' }}>{` ${task.title}`}</span>
                      </h2>
                    )}
                  </span>
                  <span className="col-md-1 pull-right pr-0 mr-0">
                    <ul className="list-inline mb-0">
                      <li style={{ padding: '0 5px' }}>
                        {titleEditable ? (
                          <button
                            type="button"
                            className="btn btn-link pull-right text-white"
                            onClick={() => this.setState({ titleEditable: !titleEditable })}
                          >
                            <i className="ft-check-circle" />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-link pull-right text-white"
                            onClick={() => this.setState({ titleEditable: !titleEditable })}
                          >
                            <i className="ft-edit-3" />
                          </button>
                        )}
                      </li>
                    </ul>
                  </span>
                </div>

                <div className="card-content collapse show">
                  <div className="card-body">
                    <div className="card-text">
                      <div className="row mb-1">
                        <span className="col-md-11 pull-left">
                          {descriptionEditable ? (
                            <textarea
                              rows="5"
                              className="form-control"
                              name="description"
                              id="description"
                              minLength="2"
                              placeholder="description"
                              ref={this.descriptionInput}
                              defaultValue={task.description}
                              onChange={() => {
                                firestore.update(
                                  {
                                    collection: 'projects',
                                    doc: projectId,
                                    subcollections: [{ collection: 'tasks', doc: taskId }],
                                  },
                                  {
                                    description: this.descriptionInput.current.value,
                                  }
                                );
                              }}
                            />
                          ) : (
                            <span>
                              <span
                                className="text-bold-700"
                                style={{ backgroundColor: '#000', color: '#fff', padding: '0px 4px' }}
                              >
                                {moment(task.createdAt.toDate()).fromNow()}
                              </span>
                              {` - ${task.description}`}
                            </span>
                          )}
                        </span>
                        <span className="col-md-1 pull-right">
                          <ul className="list-inline mb-0">
                            <li style={{ padding: '0 5px' }}>
                              {descriptionEditable ? (
                                <button
                                  type="button"
                                  className="btn btn-link pull-right"
                                  onClick={() => this.setState({ descriptionEditable: !descriptionEditable })}
                                >
                                  <i className="ft-check-circle" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-link pull-right"
                                  onClick={() => this.setState({ descriptionEditable: !descriptionEditable })}
                                >
                                  <i className="ft-edit-3" />
                                </button>
                              )}
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="center-align p-2">
                    <button
                      className="btn btn-rounded mr-1 btn-link"
                      type="button"
                      onClick={() => history.push(`/project/${projectId}`)}
                    >
                      &larr; go back to project
                    </button>
                    {'  '}
                    <button
                      onClick={this.handleOpenModal}
                      className="btn btn-rounded btn-danger pull-right"
                      type="button"
                    >
                      Delete project task
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={showModal}
            contentLabel="Delete Project Modal Confirmation"
            style={{
              overlay: {
                backgroundColor: 'rgba(0,0,0,.5)',
              },
              content: {
                color: 'black',
                margin: '5%',
              },
            }}
          >
            <div className="text-center">
              <h3>Are you sure you want to delete:</h3>
              <h2>{`${task.title}?`}</h2>
              {task.taskFinished !== true ? (
                <button
                  onClick={this.onDeleteActiveTaskClick}
                  className="btn btn-lg btn-rounded btn-danger col-md-4"
                  type="submit"
                >
                  YES, Delete Active Project Task
                </button>
              ) : (
                <button
                  onClick={this.onDeleteFinishedTaskClick}
                  className="btn btn-lg btn-rounded btn-danger col-md-4"
                  type="submit"
                >
                  YES, Delete Finished Project Task
                </button>
              )}
              {'  '}
              <button
                onClick={this.handleCloseModal}
                className="btn btn-lg btn-rounded btn-primary col-md-4"
                type="button"
              >
                NO, Close this dialog
              </button>
            </div>
          </Modal>
        </>
      );
    }
    return <Spinner />;
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: 'projects',
      doc: props.projectId,
      subcollections: [{ collection: 'tasks', doc: props.taskId }],
      storeAs: 'task',
    },
  ]),
  connect(({ firestore: { ordered } }, state, props) => ({
    task: ordered.task && ordered.task[0],
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(withRouter(ProjectTaskCardEditable));
