import { createStore, combineReducers, compose } from 'redux';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';

// reducers
import notifyReducer from './reducers/notifyReducer';
// import settingsReducer from './reducers/settingsReducer';

let firebaseConfig = {};

if (process.env.NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_PRODUCTION_API_KEY,
    authDomain: process.env.REACT_APP_PRODUCTION_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PRODUCTION_DATABASE_URL,
    projectId: process.env.REACT_APP_PRODUCTION_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PRODUCTION_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PRODUCTION_MESSAGING_SENDERID,
  };
} else {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_DEVELOPMENT_API_KEY,
    authDomain: process.env.REACT_APP_DEVELOPMENT_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEVELOPMENT_DATABASE_URL,
    projectId: process.env.REACT_APP_DEVELOPMENT_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEVELOPMENT_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEVELOPMENT_MESSAGING_SENDERID,
  };
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

// Add reactReduxFirebase enhancer when making store creator
const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig), // firebase instance as first argument
  reduxFirestore(firebase) // <- needed if using firestore
)(createStore);

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  notify: notifyReducer,
});

// Create store with reducers and initial state
const initialState = {};
const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  compose(
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : compose
  )
);

export default store;
