import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';

import Spinner from '../layout/Spinner';

import ProjectTasks from './ProjectTasks';
import ProjectFiles from './ProjectFiles';
import AddProjectTask from './AddProjectTask';

class Project extends Component {
  state = {};

  render() {
    const { project, firebase, firestore } = this.props;

    if (project) {
      return (
        <>
          <Helmet>
            <body
              className="horizontal-layout horizontal-menu 2-columns menu-expanded"
              data-open="hover"
              data-menu="horizontal-menu"
              data-col="2-columns"
              data-color="bg-gradient-x-pink-orange"
            />
            <title>{`BusinessDNA Project: ${project.title}`}</title>
          </Helmet>
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div
                className={classnames('content-header-title', {
                  h3: document.body.clientWidth >= 420,
                })}
              >
                <span style={{ color: '#ee0979' }}>PROJECT:</span>
                <span style={{ color: '#fff' }}>{` ${project.title}`}</span>
              </div>
            </div>
            <div className="content-header-right col-md-3 col-12 d-none d-md-block">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/projects">Projects</a>
                    </li>
                    {/* <li className="breadcrumb-item active">{project.title}</li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <ProjectTasks project={project} />
              <ProjectFiles project={project} firebase={firebase} firestore={firestore} />
            </div>
            <div className="col-md-4">
              <AddProjectTask project={project} />
            </div>
          </div>
        </>
      );
    }
    return <Spinner />;
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    project: state.firestore.ordered.project && state.firestore.ordered.project[0],
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  })),
  firestoreConnect(props => [
    {
      collection: 'projects',
      storeAs: 'project',
      doc: props.match.params.id,
    },
  ])
)(Project);
