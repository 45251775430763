import React from 'react';
import { Link } from 'react-router-dom';

const AppWarning = ({ data, dataId }) => (
  <div className="row">
    <div className="col-md-8">
      <div className="card">
        <div className="card-header bg-danger">
          <h3 className="text-white">Application Warning!</h3>
        </div>
        <div className="card-body">
          <h1>
            You are
            <span className="text-danger"> not allowed </span>
            {`to edit this ${data}!`}
          </h1>
        </div>
        <div className="card-footer">
          <Link className="btn btn-rounded btn-lg mr-1 btn-danger" to={`/${data}/${dataId}`}>
            &larr; Go back
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default AppWarning;
