/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint jsx-a11y/anchor-is-valid: "off" */

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';

const CardUser = ({ user, profile }) => {
  const currentPage = window.location.href;
  const isEditPage = currentPage.endsWith('/edit');
  const companyYears = moment().diff(user.startCompanyDate && user.startCompanyDate.toDate(), 'years');
  const companyDays = moment().diff(user.startCompanyDate && user.startCompanyDate.toDate(), 'days');

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="card box-shadow-1">
          <div className="card-content">
            <img className="img-fluid card-img" src={user.avatarURL || 'image.jpg'} alt="User" />
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            {!isEditPage && profile && profile.isAdmin === true ? (
              <Link to={`/user/${user.id}/edit`}>
                <span
                  className="badge badge-lg badge-pill badge-link border-primary float-right ml-1"
                  style={{ color: 'black' }}
                >
                  Edit Person
                </span>
              </Link>
            ) : null}
            <div className="card-title">{`${user.firstName} ${user.lastName}`}</div>
            <h6 className="card-subtitle text-muted">
              <strong>
                {user.startCompanyDate && companyYears === 0 ? `In company: ${companyDays} days` : null}
                {user.startCompanyDate && companyYears > 0 ? `In company: ${companyYears} years` : null}
              </strong>
            </h6>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <label className="col-md-3 label-control">
                <strong>Company Section:</strong>
              </label>
              <div className="col-md-9 text-uppercase">{user.companySection || null}</div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 label-control">
                <strong>Private Phone:</strong>
              </label>
              <div className="col-md-9">{user.privatePhone || null}</div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 label-control">
                <strong>Company Phone:</strong>
              </label>
              <div className="col-md-9">{user.companyPhone || null}</div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 label-control">
                <strong>Company Email:</strong>
              </label>
              <div className="col-md-9">{user.email}</div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 label-control">
                <strong>Private Email:</strong>
              </label>
              <div className="col-md-9">{user.privateEmail || null}</div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 label-control">
                <strong>Start date:</strong>
              </label>
              <div className="col-md-9">
                {user.startCompanyDate ? ` ${moment(user.startCompanyDate.toDate()).fromNow()}` : null}
              </div>
            </div>
          </div>
          <div className="card-footer border-top-blue-grey border-top-lighten-5 text-muted">
            <span className="float-left">
              <span>Employment Status:</span>
              {'  '}
              <strong
                className={classnames({
                  'text-success': user && user.inCompany,
                  'text-danger': user && !user.inCompany,
                })}
              >
                {user && user.inCompany ? 'Employed' : 'Not Employed'}
              </strong>
            </span>
            <span className="float-right">
              {/* <a href="#" className="card-link">
            Read More
            <i className="la la-angle-right" />
          </a> */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardUser;
