/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

class AddSettings extends Component {
  state = {
    companyName: '',
    companyShortName: '',
    companySections: [],
    taskTags: [],
    projectsAllowed: 1,
    usersAllowed: 1,
    isActive: true,
    createdAt: new Date(),
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const {
      companyName,
      companyShortName,
      companySections,
      taskTags,
      isActive,
      createdAt,
      projectsAllowed,
      usersAllowed,
    } = this.state;
    const { firestore, history, profile } = this.props;

    firestore
      .collection('settings')
      .add({
        companyName,
        companyShortName,
        companySections,
        taskTags,
        projectsAllowed,
        usersAllowed,
        isActive,
        createdAt,
        accountName: profile.companyName,
      })
      .then(() => history.push('/projects'));
  };

  render() {
    const { profile, companyName } = this.props;

    return (
      <>
        <Helmet>
          <body
            className="horizontal-layout horizontal-menu 2-columns menu-expanded"
            data-open="hover"
            data-menu="horizontal-menu"
            data-col="2-columns"
            data-color="bg-gradient-x-pink-orange"
          />
        </Helmet>
        <div className="content-wrapper-before" />
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Add new project</h3>
          </div>
          <div className="content-header-right col-md-8 col-12">
            <div className="breadcrumbs-top float-md-right">
              <div className="breadcrumb-wrapper mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/settings">Settings</a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* <Link to="/" className="btn btn-link">
              <i className="fas fa-arrow-circle-left" />
              back to dashboard
            </Link> */}
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>Create New Account Settings</h4>
            </div>
            <div className="card-body">
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="companyName">
                        <strong>Company Name:</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        id="companyName"
                        minLength="2"
                        placeholder="Enter company name..."
                        onChange={this.onChange}
                        value={companyName}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="accountName">
                        <strong>Account Name:</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="accountName"
                        id="accountName"
                        ref={this.accountNameInput}
                        defaultValue={profile.companyName}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <button className="btn btn-rounded btn-primary btn-lg btn-block" type="submit">
                  Create new project settings
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="container">
          <Link className="btn btn-outline-dark btn-block" to="/projects">
            <i className="ft-arrow-left pr-1" />
            or cancel and go back...
          </Link>
        </div>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(AddSettings);
