import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SanitizedHTML from 'react-sanitized-html';
import { Spring, config } from 'react-spring/renderprops';

const ProjectSummary = ({ project, auth }) => {
  if (project) {
    const currentPage = window.location.href;
    const isEditPage = currentPage.endsWith('/edit');
    const today = new Date().getTime() / 1000;

    const projectDaysSpan = Math.floor(
      (project.endDate && project.endDate.toDate() - (project.startDate && project.startDate.toDate())) / 86400000
    );

    return (
      <Spring config={config.gentle} from={{ marginTop: 50 }} to={{ marginTop: 0 }}>
        {props => (
          <div className="card" style={props}>
            <div className="card-header p-1">
              <h4 className="card-title float-left">
                <span>
                  {project && project.startDate
                    ? `Start: ${moment(project.startDate.toDate()).format('DD/MM/YYYY')}`
                    : 'No start date'}
                </span>
                {' : '}
                <span>
                  {project && project.endDate
                    ? `End: ${moment(project.endDate.toDate()).format('DD/MM/YYYY')}`
                    : 'No end date'}
                </span>
                <span>{project && project.endDate ? ` (span ${projectDaysSpan} days)` : null}</span>
              </h4>
              {project && project.endDate.seconds <= today ? (
                <span className="badge badge-lg badge-pill badge-danger float-right mr-0">Status: Expired</span>
              ) : (
                <span className="badge badge-lg badge-pill badge-primary float-right mr-0">Status: Active</span>
              )}
              {!isEditPage && auth.uid === project.authorId ? (
                <Link to={`/project/${project.id}/edit`}>
                  <span
                    className="badge badge-lg badge-pill badge-link border-primary float-right ml-1"
                    style={{ color: 'black' }}
                  >
                    Edit project
                  </span>
                </Link>
              ) : null}
            </div>
            <div className="card-content collapse show">
              <div className="card-footer text-center p-1">
                <div className="row">
                  <div className="col-6 col-sm-3 col-md-6 col-lg-3 border-right-blue-grey border-right-lighten-5 text-center">
                    <p className="blue-grey lighten-2 mb-0">Total Tasks</p>
                    <p className="font-medium-5 text-bold-400">{project.tasksCount || 0}</p>
                  </div>
                  <div className="col-6 col-sm-3 col-md-6 col-lg-3 border-right-blue-grey border-right-lighten-5 text-center">
                    <p className="blue-grey lighten-2 mb-0">Active Tasks</p>
                    <p className="font-medium-5 text-bold-400">{project.tasksActiveCount || 0}</p>
                  </div>
                  <div className="col-6 col-sm-3 col-md-6 col-lg-3 border-right-blue-grey border-right-lighten-5 text-center">
                    <p className="blue-grey lighten-2 mb-0">Finished Tasks</p>
                    <p className="font-medium-5 text-bold-400">{project.tasksFinishedCount || 0}</p>
                  </div>
                  <div className="col-6 col-sm-3 col-md-6 col-lg-3 text-center">
                    <p className="blue-grey lighten-2 mb-0">Total Finished</p>
                    <p className="font-medium-5 text-bold-400">
                      {project.tasksCount > 0
                        ? (((project.tasksCount - project.tasksActiveCount) * 100) / project.tasksCount).toFixed()
                        : 0}
                      <span>%</span>
                    </p>
                  </div>
                </div>
                <hr />
                <p className="text-left text-bold-700">{project.description}</p>
                <hr />
                <SanitizedHTML html={project.body} className="text-left" />
              </div>
            </div>
          </div>
        )}
      </Spring>
    );
  }
};

export default ProjectSummary;
