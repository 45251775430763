import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import moment from 'moment';
// import 'moment/locale/hr';

import Spinner from '../layout/Spinner';

class ProjectTaskComments extends Component {
  state = {};

  render() {
    const { comments, projectId, taskId, firestore } = this.props;

    return (
      <section>
        <div className="row">
          <div className="container">
            <div id="users-list" className="list-group position-relative">
              <div className="users-list-padding media-list">
                {comments ? (
                  comments.map(comment => (
                    <div className="media border-bottom-blue-grey border-bottom-lighten-3" key={comment.id}>
                      <div className="media-left pr-1">
                        <span className="avatar avatar-md">
                          <img
                            className="media-object rounded-circle"
                            src={comment.authorPhoto}
                            alt="Generic placeholder"
                          />
                        </span>
                      </div>
                      <div className="media-body w-100">
                        <h6 className="list-group-item-heading font-medium-1 text-bold-700">
                          {`${comment.authorFirstName} ${comment.authorLastName}`}
                          &nbsp;
                          {/* <i className="ft-circle font-small-2 warning" /> */}
                          <span className="font-small-3 text-muted text-bold-500">
                            {`- ${moment(comment.createdAt.toDate()).fromNow()}`}
                          </span>
                          <button
                            onClick={() =>
                              firestore.delete({
                                collection: `projects/${projectId}/tasks`,
                                doc: taskId,
                                subcollections: [{ collection: 'comments', doc: comment.id }],
                              })
                            }
                            type="submit"
                            className="btn btn-link text-light pull-right"
                          >
                            <i className="ft-trash-2" />
                          </button>
                        </h6>
                        <p className="list-group-item-text text-muted mb-0 lighten-1">
                          <strong>{comment.title}</strong>
                          {` - ${comment.body}`}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(props => [
    {
      collection: `projects/${props.projectId}/tasks/${props.taskId}/comments`,
      storeAs: 'comments',
      limit: 10,
      orderBy: ['createdAt', 'desc'],
    },
  ]),
  connect((state, props) => ({
    comments: state.firestore.ordered.comments,
  }))
)(ProjectTaskComments);
