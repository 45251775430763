/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

class AddUserComment extends Component {
  state = {
    title: '',
    body: '',
    createdAt: new Date(),
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // const newMessage = { ...this.state };
    const { title, body, createdAt } = this.state;
    const { firestore, user } = this.props;

    firestore
      .collection('users')
      .doc(user.id)
      .collection('comments')
      .add({
        title,
        body,
        createdAt,
      });
  };

  render() {
    const { title, body } = this.state;

    return (
      <>
        {/* <div className="card box-shadow-1">
          <div className="card-content">
            <img className="img-fluid card-img" src={user.avatarURL} alt="Card" />
          </div>
        </div> */}
        <div className="card">
          <div className="card-header h4">New Comment:</div>
          <div className="card-body">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="title">
                  <strong>Title:</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  id="title"
                  minLength="2"
                  placeholder="title"
                  onChange={this.onChange}
                  value={title}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="body">
                  <strong>Message:</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="body"
                  id="body"
                  minLength="2"
                  placeholder="Enter some text..."
                  onChange={this.onChange}
                  value={body}
                />
              </div>
              <button className="btn btn-rounded btn-primary" type="submit">
                Create new message
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  firebaseConnect(),
  firestoreConnect(),
  connect((state, props) => ({
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  }))
)(AddUserComment);
