import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import Spinner from '../layout/Spinner';
import AddUserComment from './AddUserComment';
import UserComments from './UserComments';
import CardUser from './CardUser';

class User extends Component {
  state = {};

  render() {
    const { user, profile } = this.props;
    // console.log(profile && profile);

    if (user) {
      return (
        <>
          <Helmet>
            <body
              className="horizontal-layout horizontal-menu 2-columns menu-expanded"
              data-open="hover"
              data-menu="horizontal-menu"
              data-col="2-columns"
              data-color="bg-gradient-x-pink-orange"
            />
          </Helmet>
          <div className="content-wrapper-before" />
          <div className="content-header row">
            <div className="content-header-left col-md-6 col-12 mb-2 h3">
              <Link to="/users/">
                <span style={{ color: '#ee0979' }}>&larr; go back to users:</span>
                <span style={{ color: '#fff' }}>{`  ${user.firstName} ${user.lastName}`}</span>
              </Link>
            </div>
            <div className="content-header-right col-md-6 col-12">
              <div className="breadcrumbs-top float-md-right">
                <div className="breadcrumb-wrapper mr-1">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/users/">Users</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to={`/users/${user.id}/`}>{`${user.firstName} ${user.lastName}`}</Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <CardUser user={user} profile={profile} />
              <UserComments user={user} />
            </div>
            <div className="col-md-3">
              <AddUserComment user={user} />
            </div>
          </div>
        </>
      );
    }
    return <Spinner />;
  }
}

export default compose(
  firebaseConnect(),
  connect((state, props) => ({
    // users: state.firestore.ordered.users,
    profile: state.firebase.profile,
    user: state.firestore.ordered.user && state.firestore.ordered.user[0],
  })),
  firestoreConnect(props => [
    {
      collection: 'users',
      storeAs: 'user',
      doc: props.match.params.id,
    },
  ])
)(User);
